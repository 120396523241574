import { Box, styled } from '@mui/material'


/////////////////////////////////////////////////////////////////
const TextWrapper = styled(Box)({
    position:"relative",
    display: 'flex', 
    padding: '2em 1em',
    justifyContent: 'center',
    '& .wrapper-div': {
        textAlign: 'center',
        '@media (max-width: 576px)': {
          textAlign: 'center',
          },
        },
    '& .wrapper-heading': {
        display:"flex",
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 700,
        letterSpacing: '0.43px',        
        textAlign: 'center',
        color:"#121212",
        fontSize:"3rem",
        '@media (max-width: 576px)': {
        fontSize : "18px",
        fontWeight: 600,
        letterSpacing: '0.43px',
        lineHeight: "25px",
        textAlign: 'left',
        color:"#121212",
        minHeight:"51px",
        paddingTop:"10px",
        paddingBottom:"10px",
        width:"90%",
        margin:"auto",
        },
        },
      '& .wrapper-text': {
          fontFamily:"var(--button-font)",
          fontSize : "16px",
          maxWidth:"952px",
          textAlign: 'center',
          color:"#000",
          '@media (max-width: 576px)': {
            fontSize : "12px",
            width:"90%",
            margin:"auto",
            justfyContent:"center",
          },
      },
  })
///////////////////////////////////////////////////////////////////////////
const MiddleSection = () => {
    return (    
        <TextWrapper>
          <div className='wrapper-div'>
              <h2 className='wrapper-heading'>
                Faq's For Coaches & Players
              </h2>
              <div className='rte rte-settings wrapper-text'>
                <p>
                  Here you'll find answers to some of the most 
                  common questions we receive about using our communication and strategy platform. 
                  Whether you're a coach looking to connect with players, or a player seeking to improve your game, 
                  that's a great resource for learning more about The Coach Corner. If you can't 
                  find the answer you're looking for, don't hesitate to reach out to our support team 
                  for further assistance.
                </p>
              </div>
          </div>
        </TextWrapper>
    )
  }

  export default MiddleSection