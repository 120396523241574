import React from 'react';
import BannerSection from './Banner1'
import LogoSection from './LogoSection'
import PictureSection1 from './PictureSection1'
import PictureSection2 from './PictureSection2'
import GetInTouch from "./GetInTouch"
import { useEffect } from 'react'
import BannerSection2 from './Banner2';
import MvpOfMonth from './MvpOfMonth';
import RosterSection from './RosterSection';
import GoEagles from './GoEagles';
import RichText from './RichText';
import RichList from './RichList';

const HomeTest = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='index__page_wrapper'>
      <BannerSection/>
      <RichText
       title={"Communication and Strategy Made Easy"}
       content={"Experience the Power of Collaborative Play Drawing and Seamless Communication with The Coach Corner's Revolutionary Platform.Join The Community and Unleash Your Team's Potential."}/>
      <PictureSection1/>
      <PictureSection2/>
      <BannerSection2/>
      <RosterSection/>
      <MvpOfMonth/>
      <RichList/>
      <GoEagles/>
      <LogoSection/>
      <GetInTouch/>
    </div>
  )
}

export default HomeTest