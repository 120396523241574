import React from "react";
import { styled, Box } from "@mui/material";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { Link } from "react-router-dom";

const FlexBox = styled("div")({
  // padding: "2em 1em",
  "@media(max-width:768px)": {
    padding: "0em 0em",
  },
});

const CardWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& h2": {
    fontSize: "5rem",
    margin: '0px 0px 30px 0px',
    "@media(max-width:768px)": {
      fontSize: "2.5em",
     },
    "& span": {
      color: "var(--secondary-color)",
    },
  },
  "& p": {
    fontFamily: "var(--button-font)",
    fontWeight: "500",
    fontSize: "1.1em",
    color: "#333",
  },
  "& > *": {
    flexBasis: "50%",
  },
  "@media (max-width:768px)": {
    flexDirection: "column",
    gap: "1em",
    width: "100%",
    "& > *": {
      flexBasis: "100%",
    },
  },
  "& .card__content-main": {
    textAlign: "left",
    padding: "3em",
    "& > *": {
      margin: "0px 0px 15px 0px",
    },
    "@media(max-width:768px)": {
      padding: "2em 1em",
    },
  },
  "& h5": {
    fontWeight: "500",
  },
  "& .button-container": {
    margin: "0px 0px 15px 0px",
  },
  "& .section--footer": {
    display: "flex",
    justifyContent:"left",
    flexDirection: 'column',
    "@media(max-width:768px)": {
      flexDirection:"column",
      justifyContent:"center",
    },
  },
  "& .text--cont": {
    margin: "0px",
    display: 'flex',
    gap: '0.5em',
    alignItems: 'center',
    "@media(max-width:768px)": {
      margin:".5rem 0rem",
      "& .first, .second":{
        fontSize: '2em'
      }
    },
    "& .first": {
      margin: "0px",
      fontFamily: "var(--heading-font)",
      fontSize: "3rem",
    },
    "& .second": {
      margin: "0px",
      fontFamily: "var(--heading-font)",
      fontSize: "1.8rem",
      paddingRight:"20px"
    },
  },
  "& .icon--style": {
    color: "var(--secondary-color)",
    fontSize: "3rem",
    margin: "auto 10px",
  },
});
const PictureSection2 = () => {
  return (
    <section className="index-section image-with-text" id='image-with-text-2'>
      <FlexBox>
        <CardWrapper className="pi cture__section-flex">
        <div className="card__image_wrapper">
            <div className="image__wrapper">
              <img src="https://res.cloudinary.com/ddkdvh5ps/image/upload/v1678021168/img6_sd4rhu.jpg" 
                   alt="bannerImg" loading="lazy" />
            </div>
          </div>
          <div className="card__content-main" data-aos="fade-up">
            <h2 className="section__title heading">
            <span>Master  </span>
              <br />
              The Coaching
            </h2>
            <p>Designed to help you master the art of coaching</p>
            <div className="rte rte-settings card__content">
              <p>Our platform offers a wide range of features to enhance your coaching skills, 
                including a library of proven strategies, expert coaching tips, and collaborative 
                play-drawing tools. 
                You can also connect with other coaches to exchange ideas and insights.</p>
            </div>
            
            <div className="section--footer"  data-aos="fade-up">
              <div className="flex-box">
                <SportsScoreIcon className="icon--style" />
                <div className="text--cont">
                  <span className="first">
                    25+
                  </span>
                  <span className="second">Verified Coaches</span>
                </div>
              </div>
              <div className="flex-box">
                <EmojiEventsIcon className="icon--style" />
                <div className="text--cont">
                  <span  className="first">
                    33+
                  </span>
                  <span className="second">Sport Organizations</span>
                </div>
              </div>
            </div>
            <div className="button-container">
              <Link to="/pages/contact-us" className="btn btn--primary btn--half">
                Read More
              </Link>
            </div>
          </div>
       </CardWrapper>
      </FlexBox>
    </section>
  );
};

export default PictureSection2;





// import React from "react";
// import {  Container, styled, Box } from "@mui/material";
// import HomeCover2 from "../../images/homepage1.jpg";
// import { Link } from "react-router-dom";
// const FlexBox = styled("div")({
//     display: "flex", margin: "100px auto",
//     "@media (max-width:700px)": {
//         flexDirection:"column",
//         margin: "50px auto"
//       },
// })

// const CardWrapper = styled(Box)({
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   "& > *":{
//    flexBasis: '50%'
//   },
//   "@media (max-width:768px)": {
//     flexDirection: 'column-reverse',
//     gap: '1em',
//     width:"100%",
//   },
//   "& .card__content-main":{
//     textAlign: 'left',
//     padding:'1em',
//     "& > *":{
//       margin: '0px 0px 15px 0px'
//     },
//     "@media(max-width:768px)":{
//       padding:'0em'
//     }
//   },
//   "& h5": { 
//      fontWeight: "500" 
//     },
//   "& .button-container": {
//     margin: '0px 0px 15px 0px'
//   },
// });
// const PictureSection2 = () => {
//   return (
//     <Container>
//       <FlexBox>
//       <CardWrapper>
//         <div className="card__image_wrapper">
//             <div className="image__wrapper">
//               <img src={HomeCover2} 
//                    alt="bannerImg" 
//                    loading="lazy"/>  
//             </div>
//           </div>  
//         <div className="card__content-main" data-aos="fade-up">
//             <h5 className="sub__heading">ADVANCED MEMBERSHIP EXPERIENCE</h5>
//             <h2 className="section__title heading">Boost customer satisfaction</h2>
//             <div className="rte rte-settings card__content">
//             <p>
//               Coach Corner offers your customers a simple booking process that
//               boosts customer satisfaction and encourages referrals. Upper Hand
//               makes it simpler for customers to interact and transact with your
//               business, whether from your website or member app, taking the
//               "work" out of their workout.
//             </p>
//             </div>
//           <div className="button-container">
//               <Link to='/pages/contact' className="btn btn--primary">Learn More</Link>
//             </div>
//             </div>
//         </CardWrapper>
//       </FlexBox>
//     </Container>
//   );
// };

// export default PictureSection2;
