import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';


const PremiumCardWrapper = styled("div")({
    flexBasis: 'calc(33% - 2em)',
    
    color: '#000',
    position: 'relative',
    "@media(max-width:992px)":{
      flexBasis: '49%',
      marginTop: '25px',
    },
    "@media(max-width:768px)":{
      flexBasis: '100%',
    },
    "& .best-seller-tag":{
      position: 'absolute',
      top: '-37px',
      left: '0',
      width: 'fit-content',
      margin: 'auto',
      right: '0',
      border: '3px solid var(--primary-color)',
      borderBottom: '0px solid lightgreen',
      padding: '0.5em 1em',
      borderRadius: '8px 8px 0px 0px',
      background: 'lightgreen',
      color: '#000',
      fontWeight: '600',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
    "& .list--flex":{
        display: 'flex',
        width: '100%',
        borderRadius: '8px',
        boxShadow: '0 1rem 2rem rgb(255 255 255 / 30%)',
        border:"3px solid var(--primary-color)",
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        "& .list__item":{
            textAlign: 'center',
            borderBottom: '1px solid var(--secondary-color)',
            padding: '0.8em 1em',
            color: '#000',
            width: '100%',
            fontSize: '18px',
            fontWeight: '500',
            background:"#fff",
            transition: 'all 0.2s ease-in',
            "&:last-child":{
                borderBottom: '0'
            },
            "&.cta":{
                padding: '1em',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '0px 0px 4px 4px',
                "&:hover":{
                    background: '#fafafa',
                    
                }
            },
            "&.price":{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                "& .discount-text":{
                    "& .original-price":{
                        color: 'var(--secondary-color)'
                    }
                },
                "& .discount-price":{
                    color: 'red',
                    padding: '0.3em 1em',
                    fontWeight: '600',
                    letterSpacing: '1px',
                    textTransform: 'uppercase',
                    "& .save-amount":{
                        paddingLeft: '5px'
                    }
                },
                "& .price":{
                    color: '#000',
                    padding: '0.3em 1em',
                    borderRadius: '4px', 
                    fontWeight: '600',
                    letterSpacing: '1px',
                    textTransform: 'uppercase',
                    "& .money-slash":{
                        color: 'var(--secondary-color)',
                        fontSize: '28px'
                    },
                    "& .money":{
                        color: 'var(--primary-color)',
                        fontSize: '32px'
                    },
                    "& .duration":{
                        textTransform: 'lowercase',
                    },
                }    
            },
            "&.header":{
                background: 'var(--primary-color)',
                color: '#fff',
                borderRadius: '0px 0px 0px 0px',
                fontSize: '26px',
                fontWeight: '600',
                letterSpacing: '1px',
                textTransform: 'uppercase',
                padding: '0.8em',
                "&:hover":{
                    background: 'var(--primary-color)',
                    borderRadius: '0px 0px 0px 0px',
                }
            },
            "&:hover":{
                background: '#fafafa',
                fontWeight: '600'
            }
        }
    }
})

const PremiumCard = () =>{
    return(
        <PremiumCardWrapper>
          <span className='best-seller-tag'>
              <span className='item__text'>Best Seller</span>
            </span>
          <div className='list--flex'>
            <div className='list__item header'>
              <span className='item__text'>Premium</span>
            </div>
            <div className='list__item price'>
              <div className='discount-price'>
                <span className='save-text'>Save</span>
                <span className='save-amount'>$11.00</span>
              </div>
              <div className='item__text price'>
                <span className='dollar'>$</span>
                <span className="money">13.00</span>
                <span className="money-slash">/</span>
                <span className='duration'>mo*</span>
              </div>
              <div className='discount-text'>Discounted from <s className="original-price">$24.00/mo</s>.
              <br/>Excl. VAT</div>
            </div>
            <div className='list__item'>
              <span className='item__text'>Unlimited Playbooks</span>
            </div>
            <div className='list__item'>
              <span className='item__text'>3 Teams</span>
            </div>
            <div className='list__item'>
              <span className='item__text'>3 Coach</span>
            </div>
            <div className='list__item'>
              <span className='item__text'>5 Collaborater Coach</span>
            </div>
            <div className='list__item'>
              <span className='item__text'>Notice Board</span>
            </div>
            <div className='list__item'>
              <span className='item__text'>Messaging</span>
            </div>
            <div className='list__item'>
              <span className='item__text'>Emails</span>
            </div>
            <div className='list__item cta'>
              <Link className='item__text btn btn--primary'>Get Started For Free</Link>
            </div>
          </div>
        </PremiumCardWrapper>
    )
}

export default PremiumCard