import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { styled } from '@mui/material/styles'
import React from 'react';
import RosterCard from './Cards/RoterCard';



const SectionWrapper = styled(Box)({
    display:"block",
    padding:"50px 0px",
    justifyContent:"space-between",
    // background: "var(--secondary-color)",
    color:"white",
    fontFamily:"var(--heading-font)",
    "& .section__heading":{
        display:"flex",
        alignItems:"end",
        justifyContent:"space-between",
        
        "& .right_side-heading":{
            fontSize:".9rem"
        },
        "& .left_side-heading":{
           fontSize:"3rem",
           fontWeight:"900",
           color:"var(--primary-color)"
        }
    },
    "& .card__container":{
        padding: '2em 0em',
        display:"flex",
        justifyContent:"space-between",
        alignItems: 'center',
        gap: '0.5em',
        "@media (max-width:768px)": {
            flexDirection: 'column',
            alignItems: 'center'
          },
    }

})
const RosterSection = () => {
  return (
    <section className='index-section roster--section' id='roster-section-1'>
      <Container >
        <SectionWrapper>
        <div className='section__heading'>
            <div><span className='left_side-heading section__title'>ROSTER</span></div>
            {/* <div ><span className='right_side-heading'>MORE NEWS</span></div> */}
        </div>
        <div className='card__container'>
            <RosterCard 
               img="https://res.cloudinary.com/ddkdvh5ps/image/upload/v1678021289/roster1_iotbrs.jpg"/>
            <RosterCard 
               img="https://res.cloudinary.com/ddkdvh5ps/image/upload/v1678021292/roster2_id8ijj.jpg"/>
            <RosterCard 
               img="https://res.cloudinary.com/ddkdvh5ps/image/upload/v1678021296/roster3_vofl9a.jpg"/>
        </div>
        </SectionWrapper>
      </Container>
    </section>
  )
}

export default RosterSection