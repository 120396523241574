import React from "react";
import { Card, Box, styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import AddLocation from '@mui/icons-material/AddLocation';
import YouTube from '@mui/icons-material/YouTube';
import Twitter from '@mui/icons-material/Twitter';
import Facebook from '@mui/icons-material/Facebook';
import { Instagram } from "@mui/icons-material";


const SectionWrapper = styled(Card)({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  width: "80%",
  margin: "40px auto",
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.3)',
  "@media (max-width: 768px)": {
    flexDirection:"column",
    margin: '1em',
    width: 'calc(100% - 1em)',
    padding: '1em'
  }
});
const SubSection = styled(Box)({
  width: "50%",
  display: "flex",
  "@media (max-width: 768px)": {
    width:"100%"
  }
});

const LeftSection = styled(Box)({
  background: "#f7f7f7",
  width: "100%",
  padding:"20px",
  "@media (max-width: 768px)": {
    padding: '1em 0.7em'
  },
  "& .heading":{
    display:"flex",
    justifyContent:"center",
    padding:"10px 0px",
    flexDirection:"column",
    width:"100%"
  },
  "& .sub-text":{
    fontFamily:"var(--button-font)",
    color:"var(--primary-color)",
    margin: '0px 0px 15px 0px'
  },
  "& li":{
    padding:"3px 0px",
    marginBottom: '10px',
  },
  "& .social-media":{
    display:"flex",
    alignItems:"center",
    "& svg":{
      fill: 'var(--primary-color)'
    },
    "& .list__flex":{
      display:"flex",
      alignItems:"center",
      gap: '0.5em',
      "&.address-link":{
        alignItems: 'start'
      },
      "&.social--links":{
        gap: '0.5em'
      }
    },
  },
  "& .mr-0":{
    margin:"0px"
  },
});

const RightSection = styled(Box)({
  width: "100%",
  padding:"20px",
  "@media (max-width: 768px)": {
    padding: '1em 0.7em'
  },
  "& .input-fields":{
    width:"90%",
    padding:"0px 0px",
    display:"flex",
    textAlign:"start",
    margin:"20px auto",
    justifyContent:"start"
  },
  "& .heading":{
    display:"flex",
    justifyContent:"center",
    marginLeft:"-30px",
    padding:"10px 0px"
  },
  "& .mr-0":{
    margin:"0px"
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--secondary-color)",
},
"& .MuiInputLabel-outlined.Mui-focused":{
    color:"var(--secondary-color)"
}
});

const ContactusForm = () => {
  return (
    <SectionWrapper>
      <SubSection>
        <LeftSection>
          <div className='git--content'>
            <div className="heading">
              <h2 className="section__title mr-0">GET IN TOUCH</h2>
              <div className="rte rte-settings">
                <p className="sub-text">
                  Have a question or need more information about Coach Corner?
                  <br/>
                  Contact us and our team will get back to you as soon as possible.
                </p>
                <div  className='contact--informations'>
                    <ul>
                    <li className="social-media">
                        <a href="tel:+1(202)4365839" target='_blank' className="list__flex">
                        <PhoneIcon/><span>+1 (202) 4365839</span> 
                        </a>
                    </li>
                    <li className="social-media">
                        <a href="mailto:info@thecoachcorner.com" target='_blank' className="list__flex">
                        <EmailIcon/><span>info@thecoachcorner.com</span>
                        </a>
                    </li>
                    <li className="social-media">
                        <span className="list__flex social--links">
                        <a href="#" target='_blank'>
                        <Facebook/>
                        </a>
                        <a href="https://twitter.com/TheCoachsCorne3" target='_blank'
                            title="The Coach Corner on Twitter">
                        <Twitter/>
                        </a>
                        <a href="#" target='_blank'>
                        <YouTube/>
                        </a>
                        <a href="https://www.instagram.com/coachcorner__/" target='_blank' 
                            title="The Coach Corner on Instagram">
                        <Instagram/>
                        </a>
                        </span>
                    </li>
                    <li className="social-media">
                        <a href="https://goo.gl/maps/JGuk43sfUKz4u7g78"  target='_blank' 
                        className="list__flex address-link">
                        <AddLocation/><span>P/O Box 45832, Baton Rouge, LA 70895</span>
                        </a>
                    </li>
                    </ul>
                </div>
                <p className="sub-text">
                  Please allow us 1-2 business 
                  days to carefully process your request. If you have a specific
                  question about your order, please don't forget to provide your order number. 
                  This will help us to assist you more quickly!
                </p>
              </div>
              
              
            </div>
          </div>
        </LeftSection>
      </SubSection>
        <SubSection>
        <RightSection>
        <div className="heading">
            <h2 className="section__title mr-0">CONTACT US</h2>
        </div>
        <TextField
          required
          id="outlined-required"
          label="Name"
          defaultValue=""
          className="input-fields"
        />
        <TextField
          required
          id="outlined-required"
          label="Email"
          defaultValue=""
          className="input-fields"
        />
        <TextField
          id="outlined-multiline-static"
          label="Message"
          multiline
          rows={5}
          defaultValue=""
          className="input-fields"
        />
          <div className="input-fields">
              <button className="btn btn--primary btn--half">
                SEND MESSAGE
              </button>
            </div>
        </RightSection>
      </SubSection>
    </SectionWrapper>
  );
};

export default ContactusForm;
