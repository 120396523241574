import {
  Box,
  Container,
  FormControl,
  InputLabel,
  OutlinedInput,
  styled,
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';


const Newsllater  = styled('div')({
  marginTop: '0.1em',
  backgroundImage: 'url(https://campo.bold-themes-cdn.com/demo-01/wp-content/uploads/sites/2/2022/11/top_cover_04_home_03.png)',
  backgroundColor:"var(--secondary-bg-color)",
  backgroundPosition: 'top center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
})

const SectionWrapper = styled(Box)({
  padding: "3em 1em",
  textAlign: "center",
  "@media (max-width:768px)": {
    padding: "2em 0",
  },
  "& h2": { 
    color: "var(--primary-color)", 
    fontFamily:"var(--button-font)", 
    fontWeight: "900", 
  },
  "& h5": { 
    color: "var(--primary-color)", 
    fontFamily:"var(--button-font)", 
    fontWeight: "500"
  },
  "& .newslatter__content": { 
    color: "#000", 
    fontFamily:"var(--button-font)", 
    maxWidth: '900px',
    margin: 'auto',
    fontSize: '1.1em',
    lineHeight: '1.3',
    fontWeight: "500"
  },
  "& .email-field": {
    maxWidth: "500px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    margin: 'auto',
    "& > .input__wrap":{
      width: '100%',
      flexBasis: '70%'
    },
    "& .input--wrap":{
      borderRadius: '100vw 0px 0px 100vw'
    },
    "& > .btn--primary":{
      width: '100%',
      flexBasis: '30%',
      borderRadius: '0px 100vw 100vw 0px'
    },
    "& .input__wrap .input--wrap":{
      width: '100%',
      borderRadius: ''
    },
    "@media (max-width:700px)": {
      width: "250px",
    },
  },
  "& .get_info_center": {
    margin: "auto",
  },
});
const GetInTouch = () => {
  return (
    <section className="index-section newslatter-section" id='newslatter-section-1'>
      <Newsllater className="newslatter--section">
        <Container>
          <SectionWrapper>
            <h2 data-aos="fade-up">Join The Coach Corner & Elevate your Coaching Game Today.</h2>
            <div class="rte rte-settings newslatter__content">
              <p>
              Stay up-to-date with the latest coaching strategies, techniques, 
              and news by subscribing to our newsletter. Get exclusive access to 
              expert advice and tips, new plays and drills, and special offers and promotions. 
              Plus, be the first to know about our latest features and updates.
              </p>
            </div>
            <div>
              <FormControl className="email-field" variant="outlined">
                <Box className='input__wrap'>
                  <InputLabel
                    htmlFor="outlined-adornment-password">
                    Your Email
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    className="input--wrap"
                    label="Your Email"
                  />
                </Box>
                <button className='btn btn--primary'>
                <SendIcon />
              </button> 
              </FormControl>
            </div>
          </SectionWrapper>
        </Container>
      </Newsllater>
    </section>
  );
};

export default GetInTouch;
