import React from 'react'
import { styled, Box } from '@mui/material'
import { Link } from 'react-router-dom'
// import HomeCover2 from "../../images/images/banner2n.jpg"

const BackGround = styled(Box)({
    backgroundImage: `url("https://res.cloudinary.com/ddkdvh5ps/image/upload/v1678020702/banner2n_n3oqfi.jpg"), linear-gradient(to bottom right, rgb(9, 32, 62), rgba(9, 32, 62, 0.2))`,
    backgroundBlendMode: 'multiply',
    backgroundSize:"cover",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    minHeight: 'calc( 100vh - 300px )',
    display: 'flex',
  
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '1em',
    
    "& .card__main":{
      display: 'flex',
      margin:"auto"
}
})

const CardWrapper = styled(Box)({
        paddingLeft:"3em",
        maxWidth:"63%",
        paddingTop:"3rem",
        marginRight: 'auto',
        textAlign:"center",
        boxShadow: "none",
        borderRadius: "4px",
        background: '##74747447',
        "@media (max-width:700px)": {
          width:"100%",
          maxWidth: '100%',
          paddingTop:"2rem",
          zIndex:"30",
          paddingLeft:".5em",

        },
         "& h2":{ 
          textAlign:"left",
          color: 'white', 
          fontWeight:"900",
          fontSize: '6.5rem',
          "@media (max-width: 576px)": {
            fontWeight:"900",
            fontSize: '3em',
          },
          "& span":{
            color:"var(--secondary-color)"
          }
        },
         "& p":{ 
          color: '#fff',
          fontSize: '18px',
          textAlign: 'left'
        },
         "& .button-container":{
            display:"flex", 
            justifyContent:"flex-start",
            alignItems: 'center',
            margin: '0px 0px 15px 40px'
         }
        
})

const BannerSection = () => {
  return (
    <section className='index-section hero-banner' id='hero-banner-1'>
      <BackGround className=''>
          <div className='card__main'
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine">     
            <CardWrapper>
              <div className='card__item'>
                  <h2 className='section__title'>
                    <span>Empowering </span> <br/>Your Team's Performance</h2>
                  <div className='rte rte-settings card__content'>
                    <p>
                      Join The Coach Corner Start Communicating and Strategizing Like Never Before!
                    </p>
                  </div> 
              </div>
              <div className='button-container card__item'>
                <Link to='/pages/contact-us' 
                      className='btn btn--secondary--outline btn--half'>Get Demo</Link>
              </div>
          </CardWrapper>
        </div>
      </BackGround>
    </section>
  )
}

export default BannerSection


