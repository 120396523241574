import React, { useEffect } from 'react';
import { styled, Box } from '@mui/material';
import BasicCards from './components/BasicCards';
import PremiumCard from './components/PremiumCard';
import BusinessCard from './components/BusinessCard';
import Questions from './components/FaqQuestions';
import HeroBanner from './components/HeroBanner';
import Contact from './components/Contact';


const questions = [
{
  id: 1,  
  question:"What payment methods do you accept?",
  ans:"We currently accept credit card payments through Stripe."
},
{
  id: 2,  
  question:"Can I upgrade or downgrade my plan at any time?",
  ans:"Yes, you can upgrade or downgrade your plan at any time from your account settings."
},
{
  id: 3,  
  question:"Is there a minimum commitment for any of your plans?",
  ans:"No, there is no minimum commitment required for any of our plans."
},
{
  id: 4,  
  question:"What happens if I exceed my plan's usage limits?",
  ans:"If you exceed your plan's usage limits, you will be charged an overage fee based on the excess usage."
},
{
  id: 5,  
  question:"Do you offer a free trial or demo of your platform?",
  ans:"Yes, we offer a free 14-day trial of our platform with no credit card required."
},
{
  id: 6,  
  question:"Can I cancel my subscription at any time, and if so, will I receive a refund?",
  ans:"Yes, you can cancel your subscription at any time. Refunds are not provided for mid-month cancellations, but you will not be charged for the following month."
},
{
  id: 7,  
  question:"How do I contact customer support if I have a question or issue?",
  ans:"You can contact our customer support team by emailing support@thecoachcorner.com or by using the live chat feature on our website."
},
{
  id: 8,  
  question:"Are there any additional fees or hidden costs associated with your plans?",
  ans:"No, there are no additional fees or hidden costs associated with our plans."
},
{
  id: 9,  
  question:"What types of coaching or strategy resources are included with each plan?",
  ans:"Each plan includes access to a variety of coaching and strategy resources, including video tutorials, practice drills, and game plans."
},
{
  id: 10,  
  question:"How often are new features or updates released, and are they included with my plan?",
  ans:"We release new features and updates on a regular basis. These updates are included with your plan at no additional cost."
}
]

const BackGround = styled(Box)({
    minHeight: 'calc( 100vh - 300px )',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection:'column',  
    "& .card__main":{
      display: 'flex',
      margin:"auto"
    },
    "@media(max-width:992px)":{
      "& section":{
        padding: '2em 1em'
      }
    }
})

const CardWrapper = styled(Box)({
    maxWidth:"800px",
    paddingTop:"3rem",
    margin: 'auto',
    textAlign:"center",
    "@media (max-width:700px)": {
      width:"100%",
      paddingTop:"2rem",
      zIndex:"30",
      paddingLeft:".5em",
    },
      "& h2":{ 
      textAlign:"center",
      color: 'var(--primary-color)', 
      fontWeight:"900",
      fontSize: '3.5em',
      "@media (max-width: 576px)": {
        fontWeight:"900",
        fontSize: '2em',
      },
    },       
})

const CardContanier = styled('section')({
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '1260px',
    width: '100%',
    marginBlock: '2em',
    flexWrap: 'wrap',
    gap: '2em'
})

const FaqContainer = styled('section')({
  display: 'flex',
  justifyContent: 'center',
  maxWidth: '1260px',
  width: '100%',
  marginBlock: '3em',
  flexWrap: 'wrap',
  gap: '2em'
})


const Pricing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    return (
    <BackGround className=''>
        <HeroBanner/>
        <section className='card__main' id='pricing-plans'>     
          <CardWrapper>
            <div className='card__item'>
                <h2 className='h1'>
                    Pricing Plans
                </h2>
            </div>
          </CardWrapper>
        </section>
        <CardContanier>
          <BasicCards />
          <PremiumCard />
          <BusinessCard/>
        </CardContanier>
        <FaqContainer>
            <h2 className='h1'>
                Frequently Asked Questions
            </h2>
          <Questions questions={questions}/>
        </FaqContainer>
        <Contact />
    </BackGround>
    )
}


export default Pricing
