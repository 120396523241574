
import React from 'react';
import { styled, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const BackGround = styled(Box)({
    background:'rgb(9, 32, 62,0.90)',
    minHeight: 'calc( 100vh - 300px )',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '1em',
    
    "& .card__main":{
      display: 'flex',
      margin:"auto"
}
})

const CardWrapper = styled(Box)({
        maxWidth:"800px",
        paddingTop:"3rem",
        margin: 'auto',
        textAlign:"center",
        "@media (max-width:700px)": {
          width:"100%",
          paddingTop:"2rem",
          zIndex:"30",
          paddingLeft:".5em",
        },
         "& h2":{ 
          textAlign:"center",
          color: 'white', 
          fontWeight:"900",
          fontSize: '3.5em',
          "@media (max-width: 576px)": {
            fontWeight:"900",
            fontSize: '2em',
          },
        },
         "& .button-container":{
            display:"flex", 
            justifyContent:"center",
            alignItems: 'center',
            margin: '0px 0px 15px 0px'
         }
        
})

const PageNotFound = () => {
    return (
        <BackGround className=''>
        <div className='card__main'>     
          <CardWrapper>
            <div className='card__item'>
                <h2 className='h1'>
                    404<br/>Page Not Found 
                </h2>
            </div>
            <div className='button-container card__item'>
              <Link to='/' 
                    className='btn btn--secondary--outline btn--half'>Back to Home</Link>
            </div>
        </CardWrapper>
      </div>
    </BackGround>
    )
}

export default PageNotFound
