import React from 'react'
import {styled,Box} from '@mui/material';

const ContainerWrapper = styled(Box)({
  width: '100%',
  paddingRight: '.75rem',
  paddingLeft: '.75rem',
  marginRight: 'auto',
  marginLeft: 'auto',
  '@media (min-width: 576px)': {
    maxWidth: '540px'
  },
  '@media (min-width: 768px)': {
    maxWidth: '720px'
  },
  '@media (min-width: 992px)': {
    maxWidth: '960px'
  },
  '@media (min-width: 1200px)': {
    maxWidth: '1140px'
  },
  '@media (min-width: 1400px)': {
    maxWidth: '1320px'
  }
})

interface ContianerProps {
  children: any
}

const Container: React.FC<ContianerProps> = ({children}) => {
  return (
    <ContainerWrapper>
      {children}
    </ContainerWrapper>
  )
}

export default Container