import React, { useEffect } from 'react';
import { styled, Box } from '@mui/material';
import IconsCard from './FeatureCard';

const BackGround = styled(Box)({
    background: 'rgb(9, 32, 62,0.95)',
    minHeight: 'calc( 100vh - 300px )',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '1em',

    "& .card__main":{
      display: 'flex',
      margin:"auto",
      flexDirection: 'column'
}
})

const CardFooter = styled('div')({
    maxWidth: '800px',
    fontSize: '1.2em',
    color: '#fff',
    margin: '2em auto',
    padding: '1em',
    textAlign: 'center',
    "@media (max-width:768px)": {
      padding: '0'
    }
})

const CardWrapper = styled(Box)({
        paddingLeft:"3em",
        maxWidth:"63%",
        margin: 'auto',
        paddingTop:"1rem",
        marginRight: 'auto',
        textAlign:"center",
        boxShadow: "none",
        borderRadius: "4px",
        textAlign: 'center',
        background: '##74747447',
        "@media (max-width:768px)": {
          width:"100%",
          maxWidth:'unset',
          paddingTop:"2rem",
          paddingLeft:"0em",
        },
         "& h2":{ 
          textAlign:"center",
          color: 'var(--secondary-color)', 
          fontWeight:"900",
          fontSize: '4em',
          "@media (max-width: 576px)": {
            fontWeight:"900",
            fontSize: '3em',
          },
          // margin: '0px 0px 15px 0px',
          "& span":{
            color:"var(--secondary-color)"
          }
        },
         "& p":{ 
          color: '#fff',
          fontSize: '18px',
          textAlign: 'center'
        },
         "& .button-container":{
            display:"flex", 
            justifyContent:"flex-start",
            alignItems: 'center',
            margin: '0px 0px 15px 40px'
         }
        
})
const CardContainer = styled(Box)({
    display:"flex",
    justifyContent:"space-between",
    margin:"auto auto 3em",
    flexWrap: 'wrap',
    gap: '1em',
    maxWidth:"1200px",
    "@media(max-width:768px)": {
      minWidth:"100%",
      flexDirection:"column",
      alignItems:"center",
      margin:"10px auto"
      
     },
    
})
const Features = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    return(
        <BackGround className=''>
     
        <div className='card__main'
             data-aos="fade-right"
             data-aos-offset="300"
             data-aos-easing="ease-in-sine">     
          <CardWrapper>
            <div className='card__item'>
                <h2 className='h1'>Features of <br/>The Coach Corner</h2>
                <div className='rte rte-settings card__content'>
                  <p>The Coach Corner offers a variety of tools to help coaches
                     and players communicate, strategize, 
                    and plan their games. Here are some of its key features:</p>
                </div> 
            </div>
        </CardWrapper>
        <CardContainer>
            <IconsCard 
              title={'Communication tools'} 
              content={'Coaches and players can use the platform to send messages, share files, and schedule events.'}
              />
            <IconsCard 
              title={'Play drawing'} 
              content={'Coaches can use the built-in drawing tool to create and share plays with their team.'}
              />
            <IconsCard 
              title={'Game planning'} 
              content={'Coaches can use the platform to plan their games, set up lineups, and assign tasks to players.'}
              />
            <IconsCard 
              title={'Video analysis'} 
              content={'Coaches can upload videos of games or practices, and use the platform to analyze them and provide feedback to players.'}
              />
            <IconsCard 
              title={'Statistics tracking'} 
              content={"Coaches can track their team's performance over time, and identify areas for improvement."}
              />
            <IconsCard 
              title={'Coach Certification'} 
              content={"Become a certified coach with our online certification program. Learn new coaching strategies, techniques, and best practices, and earn a certificate to showcase your skills and expertise."}
              />
        </CardContainer>
        <CardFooter>
        <div className='rte rte-settings card__content'>
            <p>These features are designed to help coaches and players stay organized, 
                focused, and connected, and to give them the tools they need to succeed on the field or court.</p>
        </div> 
        </CardFooter>
      </div>
    </BackGround>
    )
}

export default Features