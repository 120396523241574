import React from 'react'
import { Box, Container, styled } from '@mui/material'

const LogoSectionWrapper = styled(Box)({
    padding: '1em 0em',
    backgroundColor:"#fff",
    backgroundImage: "url(https://campo.bold-themes-cdn.com/demo-01/wp-content/uploads/sites/2/2022/11/top_cover_home_03.png)",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    textAlign: 'center',
    "@media (max-width:768px)": {
      padding: '3em 1em 1em',
      "& .logo-list-container":{
        padding: '0'
      }
    },
    "& .logo_list_img-wrapper":{
      maxHeight:"120px", 
      display: 'flex',
      justifyContent: 'center',
      overflow: 'hidden',
      "& img":{
        maxWidth: '100%',
        display:'block'
      }
    },
    "& .section__title":{
      maxWidth:"500px", 
      margin:"auto", 
      "@media (max-width:700px)": {
        width:"100%",
      },
    },
    "& h2": { 
      color: "var(--primary-color)", 
      fontWeight: "600", 
      fontFamily:"var(--button-font)",
      },
      "& p": { 
        fontFamily:"var(--button-font)",
        color: "white", 
        fontWeight: "500", 
        },
    "& .logo__section-content": { 
      maxWidth:"700px", 
      margin:"auto", 
      "@media (max-width:768px)": {
        width:"100%"
      },
    },
    "& .logo-work-container": { 
      display:"flex", 
      maxWidth:"1200px",
      margin: 'auto',
      justifyContent:"space-between",
      gap: '1em', 
      padding:"3em 0em", 
      flexWrap: 'wrap',
      "@media(max-width:768px)":{
        gap: '0.5em'
      },
      "& .logo_list_img-wrapper":{
        flexBasis: 'calc(16.6% - 1em)',
        "@media(max-width:768px)":{
          flexBasis: '48%'
        }
      }
    },
})



const LogoSection = () => {
  return (
    <section className='index-section logo-list-section' id='logo-list-1'>
      <LogoSectionWrapper>
        <Container className='logo-list-container'>
          <div className='work-sample-container'  data-aos="fade-up">
            <h2>OUR PARTNERS</h2>
            <div className='logo-work-container'>
                <div className="logo_list_img-wrapper">
                    <img src="https://res.cloudinary.com/eazy-ticks/image/upload/h_150,w_150/v1675872842/ri6ak4yspcnqikg593zl.jpg" 
                        alt="logo" 
                        className="logo-worksection-img" 
                        loading="lazy"/>
                </div>
                <div className="logo_list_img-wrapper">
                    <img src="https://ezt-v2.rfdevelopers.com/static/media/partner-1.13f0bc8ab4d99251db91.png" 
                        alt="logo" 
                        className="logo-worksection-img" 
                        loading="lazy"/>
                </div>
                <div className="logo_list_img-wrapper">
                    <img src="https://ezt-v2.rfdevelopers.com/static/media/partner-7.a7820ed4f19e8d5858d4.png" 
                        alt="logo" 
                        className="logo-worksection-img" 
                        loading="lazy"/>
                </div>                
                <div className="logo_list_img-wrapper">
                    <img src="https://res.cloudinary.com/eazy-ticks/image/upload/w_120,h_120/v1677595668/rrkpbxmls8yyt8sdn1sj.jpg" 
                        alt="logo" 
                        className="logo-worksection-img" 
                        loading="lazy"/>
                </div>
                <div className="logo_list_img-wrapper">
                    <img src="https://res.cloudinary.com/eazy-ticks/image/upload/h_150,w_150/v1677575974/xdatoqd8rchn1rma8nid.jpg" 
                        alt="logo" 
                        className="logo-worksection-img" 
                        loading="lazy"/>
                </div>
                <div className="logo_list_img-wrapper">
                    <img src="https://res.cloudinary.com/eazy-ticks/image/upload/w_150,h_150/v1676982452/dnnqgkegda2vsmvgiqt9.png" 
                        alt="logo" 
                        className="logo-worksection-img" 
                        loading="lazy"/>
                </div>
            </div>
          </div>
        </Container>
      </LogoSectionWrapper>
    </section>
  )
}

export default LogoSection