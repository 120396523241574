import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {styled  } from '@mui/material';
import { Link } from 'react-router-dom';
import AcUnitIcon from '@mui/icons-material/AcUnit';

const CardWrapper = styled(Card)({
        flexBasis:"calc(33% - 1em)",
        border:".5px solid var(--secondary-color)",
        background:"#fff",
        color: '#000',
        ":hover":{
            background:"var(--secondary-color)",
            "& .icon-cont":{
                "& .icon":{
                    color:"white" 
                 }
        },

        },
        "& .icon-cont":{
            justifyContent:"center",
            display:"flex",
            alignItems:"center",
            padding:"1em",
            "& .icon":{
                fontSize:"3em",
                color:"var(--secondary-color)",                
            }
        },
        "& h2":{
            fontFamily:"var(--heading-font)",
            fontSize:"2em",
            color:"#000",
            fontWeight:"700",
            textAlign:"center",
            textTransform: 'capitalize',
            margin: '0px 0px 15px 0px'
        },
        "& span":{
            color: '#000',
            textAlign:"center",
            margin: '0px 0px 15px 0px'
        },
   })



export default function IconsCard({icon,title,content}) {
  return (
    <CardWrapper>
      <div className='icon-cont'>
        <AcUnitIcon className='icon'/>
      </div>
      <CardContent>
        <Typography variant="h2">{title}</Typography>
        <Typography variant="span">
           {content}
        </Typography>
      </CardContent>
    </CardWrapper>
  );
}