import React from 'react';
import { styled, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const BackGround = styled(Box)({
    backgroundImage: `url(https://res.cloudinary.com/ddkdvh5ps/image/upload/v1678020979/img9_o6r18b.jpg), linear-gradient(to bottom right, rgb(9, 32, 62), rgba(9, 32, 62, 0.3))`,
    backgroundBlendMode: 'multiply',
    backgroundSize:"cover",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    minHeight: 'calc( 100vh - 300px )',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '1em',
    "@media (max-width:700px)": {
      width:"100%",
     padding:"0em",
   },
    "& .card__main":{
      display: 'flex',
    //   margin:"auto"
}
})

const CardWrapper = styled(Box)({
        width:"63%",
        justifyContent:"left",
        boxShadow: "none",
        borderRadius: "4px",
        background: 'transparent',
        "& .card__item":{
            display:"flex",
            justifyContent:"left",

        },
        "& .left-side-content":{
            fontFamily:"var(--heading-font)",
            padding:"20px",
            "@media(max-width:768px)":{
              display: 'none'
            }
        },
        "& .text-cont__right":{
            display:"flex",
            flexDirection:"column"
        },
        "& .top-text":{
            fontSize:"3.5rem",
            color:"white"
        },
        "& .bottom-text":{
            fontSize:"1.3rem",
            color:"white"
        },
        "@media (max-width:768px)": {
          width:"100%",
          padding:"1em",
        },
         "& h2":{ 
          textAlign:"left",
          color: 'white', 
          fontWeight:"900",
          fontSize: '8rem',
          "@media (max-width: 576px)": {
            fontWeight:"900",
            fontSize: '3.5rem',
          },
          // margin: '0px 0px 15px 0px',
          "& span":{
            color:"var(--secondary-color)"
          }
        },
        
        
})

const Outdated = styled('div')({
  maxWidth: '580px',
  marginLeft: 'auto',
  "& p":{ 
    color: '#fff',
    fontSize: '18px'
  },
   "& .button-container":{
      display:"flex", 
      justifyContent:"flex-start",
      alignItems: 'center',
      margin: '0px 0px 15px 40px'
   }
})

const MvpOfMonth = () => {
  return (
    <section className='index-section mop-section' id='mop-section-1'>
      <BackGround>
          <div className='card__main'
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine">     
            <CardWrapper>
              <div className='card__item'>
                  <div>
                    <h2 className='left-side-content'>01</h2> 
                  </div>
                  <h2 className='section__title' > 
                    <span className='text-cont__right'>
                        <span className='bottom-text'>The Coach Corner</span>
                        <span className='top-text'>Develop</span>  
                        <span>Winning Strategies</span>  
                    </span>
                  </h2>
              </div>
              <Outdated>
                <div className='rte rte-settings card__content'>
                    <p>
                      improve your skills and help your team achieve their full potential.
                      <br/>
                      Together, we'll create winning strategies and elevate your coaching game to new heights.
                    </p>
                  </div> 
                  <div className='button-container card__item'>
                    <Link to='/pages/contact-us' 
                          className='btn btn--secondary--outline btn--half'>Sign Up Now</Link>
                  </div>
              </Outdated>
          </CardWrapper>
        </div>
      </BackGround>
    </section>
  )
}

export default MvpOfMonth


