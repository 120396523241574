import React, { useEffect } from 'react';
import Banner from './AboutUsComponents/Banner';
import GetToKnowUs from './AboutUsComponents/GetToKnowUs';
import  OurPartners  from './AboutUsComponents/OurPartners';
import Testimonials from  './AboutUsComponents/Testimonials';

const IndexAboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
        <Banner/>
        <GetToKnowUs/>
        <Testimonials/>
        <OurPartners/>
    </div>
  )
}

export default IndexAboutUs