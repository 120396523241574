import React from "react";
import { styled, Box } from "@mui/material";

const CardWrapper = styled(Box)({
  display: "flex",
  "@media (max-width: 576px)": {
    flexDirection: "column",
  },

  "& .wrapper-background": {
    height: "100%",
    width: "100%",
    backgroundImage: `url("https://res.cloudinary.com/ddkdvh5ps/image/upload/v1678021820/aboutus_nqcd8v.jpg"),  linear-gradient(to bottom right, rgb(9, 32, 62), rgba(9, 32, 62, 0.2)) `,
    backgroundRepeat:"no-repeat",
    backgroundSize:"cover",
    backgroundBlendMode: "multiply",
    "& h2": {
      // marginTop: "218px",
      zIndex: "10",
      color: "white",
      padding:"40px inherit",
      textAlign: "left",
      width: "75%",
      fontSize: "6.5rem",
      fontWeight: 700,
     margin:"80px auto"
    },
    "& span": {
      color: "var(--secondary-color)",
    },
    "& p": {
      color: "white",
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "35.3px",
      letterSpacing: "0.35px",
      width: "759px",
      marginLeft: "auto",
      marginRight: "auto",
    },
    "@media (max-width: 576px)": {
      "& .wrapper-background": {
        height: "100%",
        minHeight: '70vh',
        width: "100%",
      },
      "& h2": {
        // marginTop: "218px",
        zIndex: "10",
        color: "white",
        padding:"40px inherit",
        textAlign: "left",
        width: "75%",
        fontSize: "2em",
        fontWeight: 700,
       margin:"30px auto"
      },
      "& span": {
        color: "var(--secondary-color)",
      },
      "& p": {
        color: "white",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "35.3px",
        letterSpacing: "0.35px",
        // width: "759px",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
  },
});

const Banner = () => {
  return (
    <CardWrapper>
      <div className="wrapper-background">
        <h2 className="section__title">
          Empowering<span> Sports Teams and Coaches</span> with 
          Cutting-Edge <span>Communication and Strategy Tools</span>
        </h2>
      </div>
    </CardWrapper>
  );
};

export default Banner;
