import styled from '@emotion/styled';
import React from  'react';
import AddTaskIcon from '@mui/icons-material/AddTask';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

const Testi = styled('section')({
    maxWidth: '1000px',
    margin: 'auto',
    padding: ' 2em',
    "& .main__flex":{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '1em',
        flexDirection: 'column',
        "& .flex--item":{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            gap: '1em',
            "@media(max-width:768px)":{
                flexDirection: 'column'
            },
            "& > *":{
                margin: '0px 0px 15px 0px'
            },
            "& .icon":{
                width: '100px',
                height: '100px',
                fill: 'var(--primary-color)',
            }
        }
    }
})


function Testimonials(){
    return(
        <Testi className='testimonial-section section--newly'>
           <div className='main__flex'>
            <div className="flex--item">
                <div className='svg--wrapper'>
                    <AddTaskIcon className='icon icon-task'/>
                </div>
                <div className="content-wrap">
                <h3>Our Mission</h3>
                <p>
                Empower coaches and players by providing a platform that fosters communication, 
                collaboration, and growth within the sports community.
                </p>
                </div>
            </div>
            <div className="flex--item">
                <div className='svg--wrapper'>
                    <AdminPanelSettingsIcon className='icon icno-admin'/>
                </div>
                <div className="content-wrap">
                <h3>Our Vision</h3>
                <p>
                    platform to connect, share knowledge, and 
                    improve their skills. We aspire to create a community where individuals 
                    can learn, grow, and succeed together, ultimately transforming the world of sports.
                    </p>
                    </div>
            </div>
            <div className="flex--item">
                <div className='svg--wrapper'>
                    <AddShoppingCartIcon className='icon icon-shop'/>
                </div>
                <div className="content-wrap">
                <h3>Our Priority</h3>
                <p>user experience, collaboration, innovation, community, and accessibility. 
                     providing a seamless and 
                    user-friendly platform that promotes collaboration between coaches and players</p>
                    </div>
            </div>
           </div>
        </Testi>
    )
}


export default Testimonials