import { Box, styled } from '@mui/material'
import MiddleSection from './FAQcomponents/MiddleSection'
import UpperSection from './FAQcomponents/UpperSection'
import FAQsForm from './FAQcomponents/FAQsForm'
import { useEffect } from 'react'


////////////////////////////////////////////////////////////////////
export const BannerWrapper = styled(Box)({
      // position:"relative",
      background:"#F5F5F5",
      '& .wrapper-question-box': {
      marginTop:"20px",
      minHeight:"1000px",
      '@media (max-width: 576px)': {
      position:"relative",
      width:"300px",      },    
    },
    '& .wrapper-question-text-box': {
      marginBottom:"30px",
      background: '#FFFFFF',
      boxShadow: '-2px 2px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '8px',
      '@media (max-width: 576px)': {
      marginBottom:"30px",
      background: '#FFFFFF',
      boxShadow: '-2px 2px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '8px',
      width:"300px",
      }, 
    },
   '& .wrapper-question-text-question': {
      fontSize : "22px",
      fontWeight: 600,
      letterSpacing: '0.43px',
      lineHeight: "30.14px",
      color:"#212121",
      fontFamily:"Poppins",
      textAlign:"left",
      paddingLeft:"29px",
      paddingTop:"18px",
      paddingBottom:"18px",
      '@media (max-width: 576px)': {
        fontSize : "18px",
        fontWeight: 400,
        letterSpacing: '0.43px',
        lineHeight: "25px",
        color:"#212121",
        fontFamily:"Poppins",
        textAlign:"left",
        paddingTop:"12px",
        paddingBottom:"12px",
        width:"300px",
       },
      },
   '& .wrapper-question-text-ans': {
    fontFamily: 'Poppins',
    fontSize: '17px',
    fontWeight: 400,
    lineHeight: '31px',
    letterSpacing: '0.43160051107406616px',
    textAlign: 'left',
    color:"#212121",
    paddingLeft:"26px",
    paddingRight:"35px",
    '@media (max-width: 576px)': {
      position:"relative",
      width:"300px",
      },    
   },
   '& .wrapper-icon': {
    transform: "rotate(45deg)"
   },
   
  })


/////////////////////////////////////////////////////////////////////
function IndexFAQ() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <UpperSection/>
      <BannerWrapper>
        <MiddleSection/>
        <FAQsForm/>
      </BannerWrapper>
    </div>
  )
}

export default IndexFAQ