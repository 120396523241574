import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {styled  } from '@mui/material';
import { Link } from 'react-router-dom';
import VerifiedIcon from '@mui/icons-material/Verified';


const CardWrapper = styled(Card)({
        flexBasis:"calc(24% - 0.5px)",
        border:".5px solid var(--secondary-color)",
        background: 'transparent',
        fontFamily:"var(--heading-font)",
        "@media(max-width:768px)": {
          margin:"20px 0px",
          flexBasis:"100%",
          flexShrink: '0'
         },
        ":hover":{
            background:"var(--secondary-color)",
            "& .icon-cont":{
                "& .icon":{
                    color:"white" 
                 }
        },

        },
        "& .icon-cont":{
            justifyContent:"center",
            display:"flex",
            alignItems:"center",
            minHeight:"50px",
            padding: '1em',
            "& .icon":{
                fontSize:"5rem",
                color:"var(--secondary-color)",      
            }
        },

        "& h5":{
            fontFamily:"var(--heading-font)",
            fontSize:"2em",
            color:"#fff",
            fontWeight:"700",
            textAlign:"center",
            margin: '0px 0px 15px 0px',
            minHeight: '50px'
        },
        "& span":{
          color: '#fff',
          fontSize: '1.1em'
        },
  })
export default function IconsCard({title, content}) {
  return (
    <CardWrapper className='feature__card card'>
        <div className='icon-cont'>
      <VerifiedIcon className='icon'/>
      </div>
      <CardContent>
        <Typography variant="h5" >{title}</Typography>
        <Typography variant="span" >{content}</Typography>
      </CardContent>
    </CardWrapper>
  );
}