import React, { useEffect } from 'react'
import ContactusForm from './ContactusForm'

const Contactus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='contact-us-page'>
      <ContactusForm/>
    </div>
  )
}

export default Contactus