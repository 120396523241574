import React from 'react'
import { Box, Container, styled } from '@mui/material'
import { Link } from 'react-router-dom'

const LogoSectionWrapper = styled(Box)({
    padding: '4em 1em 1em',
    margin: '0em 0em 4em',
    textAlign: 'center',
    // maxWidth:"1320px",
    backgroundColor:"#fff",
    backgroundImage: "url(https://campo.bold-themes-cdn.com/demo-01/wp-content/uploads/sites/2/2022/11/top_cover_home_03.png)",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    "@media (max-width:768px)": {
      padding: '4em 1em 2em',
      margin: '0',
      "& .richlist--container":{
        padding: '0'
      }
    },
    "& .btn__wrapper":{
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    "& .section__title":{
      maxWidth:"500px", 
      margin:"auto", 
      "@media (max-width:700px)": {
        width:"100%",
      },
    },
    "& h2": { 
      color: "var(--primary-color)", 
      fontWeight: "600", 
      fontFamily:"var(--button-font)",
      },
      "& p": { 
        fontFamily:"var(--button-font)",
        color: "#333", 
        fontSize: '1.2em',
        fontWeight: "500", 
        },
    "& .logo__section-content": { 
      textAlign: 'left',
      maxWidth:"760px", 
      margin:"auto", 
      "& .benefit":{
        listStyleType: 'disc',
        listStylePosition: 'outside',
        "& li::marker":{
            color: 'var(--secondary-color)'
        }
      },
      "@media (max-width:768px)": {
        width:"100%"
      },

    },
})



const RichList = () => {
  return (
    <section className='index-section richlist--section' id='richlist-1'>
      <LogoSectionWrapper>
        <Container className='richlist--container'>
          <div className='work-sample-container' data-aos="fade-up">
            <h2>Partner With The Coach Corner <br/> Elevate Your Coaching Game</h2>
            <div class="rte rte-settings logo__section-content">
              <p>
              Our platform is designed to make communication and collaboration easier than ever before. 
              With our comprehensive library of plays, expert coaching tips, and collaborative play-drawing 
              feature, you can be sure that you have everything you need to develop a winning game plan.
              <br/>
                By partnering with The Coach Corner, you'll have access to a range of benefits, including: 
                <ol className='benefit'>
                  <li>Exposure to a wider audience of coaches and players</li>
                  <li>Opportunities to collaborate and network with other coaches and sports organizations</li>
                  <li>Access to our comprehensive library of plays and coaching resources</li>
                  <li>The ability to share your own expertise and coaching tips with our community</li>
                </ol>           
              </p>
              
            </div> 
            <div className='btn__wrapper'>
              <Link to='pages/contact-us' 
                    className='btn btn--primary btn--half'>
                      Join Our Community</Link>
            </div>
          </div>
        </Container>
      </LogoSectionWrapper>
    </section>
  )
}

export default RichList