import React from 'react'
import PrivacyPolicyComponent from './PrivacyPolicyComponent'
import { useEffect } from 'react';
const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    
    <div>
        <PrivacyPolicyComponent/>
    </div>
  )
}

export default PrivacyPolicy