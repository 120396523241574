import React from "react";
import { styled, Box } from "@mui/material";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { Link } from "react-router-dom";

const FlexBox = styled("div")({
  padding: "",
  "@media(max-width:768px)": {
    padding: "0em 0em",
  },
});

const CardWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "@media(max-width:768px)": {
    width:"99%"
  },
  "& h2": {
    fontSize: "5rem",
    marginBottom: '25px',
    "@media(max-width:768px)": {
      fontSize: "2.5rem",
     },
    "& span": {
      color: "var(--secondary-color)",
    },
  },
  "& .card__content": {
    fontFamily: "var(--button-font)",
    fontWeight: "500",
    fontSize: '1.1em',
    letterSpacing: '1.5px',
    lineHeight: '1.5',
    textTransform: 'capitalize',
    color: "#333",
  },
  "& > *": {
    flexBasis: "50%",
  },
  "@media (max-width:768px)": {
    flexDirection: "column-reverse",
    gap: "1em",
    width: "100%",
  },
  "& .card__content-main": {
    textAlign: "left",
    padding: "3em",
    "& .h1":{
      color: 'var(--secondary-color)'
    },
    "& h2":{
      margin: '0px 0px 30px 0px'
    },
    "& > *": {
      margin: "0px 0px 15px 0px",
    },
    "@media(max-width:768px)": {
      padding: "2em 1em",
    },
  },
  "& h5": {
    fontWeight: "500",
  },
  "& .button-container": {
    margin: "0px 0px 15px 0px",
  },
  "& .section--footer": {
    display: "flex",
    justifyContent:"left",
    "@media(max-width:768px)": {
      flexDirection:"column",
      justifyContent:"center",
    },
  },
  "& .text--cont": {
    margin: "0px",
    display: 'flex',
    alignItems: 'center',
    gap: '0.5em',
    "@media(max-width:768px)": {
      margin:".5rem 0rem"
    },
    "& .first": {
      margin: "0px",
      fontFamily: "var(--heading-font)",
      fontSize: "3em",     
    },
    "& .second": {
      margin: "0px",
      fontFamily: "var(--heading-font)",
      fontSize: "2em",
      paddingRight:"20px"
    },
  },
  "& .icon--style": {
    color: "var(--secondary-color)",
    fontSize: "3rem",
    margin: "auto 10px",
  },
});
const PictureSection1 = () => {
  return (
    <section className="index-section image-with-text" id='image-with-text-1'>
      <FlexBox>
        <CardWrapper className="picture__section-flex">
          <div className="card__content-main" data-aos="fade-up">
            <h3 className="h1">
               The Coach Corner
            </h3>
            <h2 className="section__title heading">
              Strategy Start's Here
            </h2>
            <div className="rte rte-settings card__content">
              <p>
              <span className='primary-color'>The Coach Corner </span> 
              is the ultimate platform for coaches and 
              players to come together and develop winning strategies. 
              Our comprehensive library of plays, expert coaching tips,
              and collaborative play-drawing feature make it easier than ever to 
              communicate and strategize effectively.
              Join The Coach Corner now and start taking your coaching skills to the next level!
              </p>
            </div>
            <div className="section--footer" data-aos="fade-up">
              <div className="flex-box">
                <SportsScoreIcon className="icon--style" />
                <div className="text--cont">
                  <span className="first">120+</span>
                  <span className="second">Coaches</span>
                </div>
              </div>
              <div className="flex-box">
                <EmojiEventsIcon className="icon--style" />
                <div className="text--cont">
                  <span className="first">3k+</span>
                  <span className="second">Players</span>
                </div>
              </div>
            </div>
            <div className="button-container">
              <Link to="/pages/contact-us" 
                    className="btn btn--primary btn--half">
                   Get Demo
              </Link>
            </div>           
          </div>
          <div className="card__imag e_wrapper">
            <div className="imag e__wrapper">
              <img 
                src="https://res.cloudinary.com/ddkdvh5ps/image/upload/v1678021079/img5_tm3hxp.jpg" 
                alt="Strategy Start's Here - The Coach Corner" 
                loading="lazy" />
            </div>
          </div>
        </CardWrapper>
      </FlexBox>
    </section>
  );
};

export default PictureSection1;
