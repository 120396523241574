import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';


const BasicCardWrapper = styled("div")({
    flexBasis: 'calc(33% - 2em)',
    color: '#000',
    "@media(max-width:992px)":{
      flexBasis: '49%',
    },
    "@media(max-width:768px)":{
      flexBasis: '100%',
    },
    "& .list--flex":{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        border:".5px solid var(--secondary-color)",
        borderRadius: '8px',
        background:"#fff",
        boxShadow: '0 1rem 2rem rgb(255 255 255 / 30%)',
        "& .list__item":{
            textAlign: 'center',
            borderBottom: '1px solid var(--secondary-color)',
            padding: '0.8em 1em',
            color: '#000',
            width: '100%',
            fontSize: '18px',
            fontWeight: '500',
            transition: 'all 0.2s ease-in',
            "&:last-child":{
                borderBottom: '0'
            },
            "&.cta":{
                padding: '1em',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                "&:hover":{
                    background: '#fafafa',
                    borderRadius: '0px 0px 8px 8px',
                }
            },
            "&.price":{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                "& .discount-text":{
                    "& .original-price":{
                        color: 'var(--secondary-color)'
                    }
                },
                "& .discount-price":{
                    color: 'red',
                    padding: '0.3em 1em',
                    fontWeight: '600',
                    letterSpacing: '1px',
                    textTransform: 'uppercase',
                    "& .save-amount":{
                        paddingLeft: '5px'
                    }
                },
                "& .price":{
                    color: '#000',
                    padding: '0.3em 1em',
                    borderRadius: '4px', 
                    fontWeight: '600',
                    letterSpacing: '1px',
                    textTransform: 'uppercase',
                    "& .money-slash":{
                        color: 'var(--secondary-color)',
                        fontSize: '28px'
                    },
                    "& .money":{
                        color: 'var(--primary-color)',
                        fontSize: '32px'
                    },
                    "& .duration":{
                        textTransform: 'lowercase',
                    },
                }    
            },
            "&.header":{
                background: 'var(--primary-color)',
                color: '#fff',
                borderRadius: '8px 8px 0px 0px',
                fontSize: '26px',
                fontWeight: '600',
                letterSpacing: '1px',
                textTransform: 'uppercase',
                padding: '0.8em',
                "&:hover":{
                    background: 'var(--primary-color)',
                    borderRadius: '8px 8px 0px 0px',
                }
            },
            "&:hover":{
                background: '#fafafa',
                fontWeight: '600'
            }
        }
    }
})

const BusinessCard = () =>{
    return(
        <BasicCardWrapper>
          <div className='list--flex'>
            <div className='list__item header'>
              <span className='item__text'>Bussiness</span>
            </div>
            <div className='list__item price'>
              <div className='discount-price'>
                <span className='save-text'>Save</span>
                <span className='save-amount'>$35.00</span>
              </div>
              <div className='item__text price'>
                <span className='dollar'>$</span>
                <span className="money">115.00</span>
                <span className="money-slash">/</span>
                <span className='duration'>mo*</span>
              </div>
              <div className='discount-text'>Discounted from <s className="original-price">$150.00/mo</s>.
              <br/>Excl. VAT</div>
            </div>
            <div className='list__item'>
              <span className='item__text'>Unlimited Playbooks</span>
            </div>
            <div className='list__item'>
              <span className='item__text'>Unlimited Teams</span>
            </div>
            <div className='list__item'>
              <span className='item__text'>Unlimited Coach</span>
            </div>
            <div className='list__item'>
              <span className='item__text'>Unlimited Collaborater Coach</span>
            </div>
            <div className='list__item'>
              <span className='item__text'>Unlimited Notice Board</span>
            </div>
            <div className='list__item'>
              <span className='item__text'>Unlimited Messaging</span>
            </div>
            <div className='list__item'>
              <span className='item__text'>Unlimited Emails</span>
            </div>
            <div className='list__item cta'>
              <Link className='item__text btn btn--primary'>Get Started For Free</Link>
            </div>
          </div>
        </BasicCardWrapper>
    )
}

export default BusinessCard