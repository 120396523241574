
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { Box ,styled } from '@mui/material'
import plus from "../images/plusSign.png"
import "./style.css"

/////////////////////////////////////////////////////////////////////////////////////////////
const BannerWrapper = styled(Box)({
  background:"#F5F5F5",
  border:"0",
  margin:"auto",
  width:"987px",
  '@media (max-width: 576px)': {
    position:"relative",
    width:"90%",  
 },    
  '& .wrapper-question-box': {
    
  marginTop:"20px",
  minHeight:"1000px",
  '@media (max-width: 576px)': {
  position:"relative",
        width:"100%",  
        minHeight:"70px",
        marginBottom:"20px" ,
        marginLeft:"auto",
        marginRight:"auto"   
      },    
},
'& .wrapper-question-text-box': {
  
  marginBottom:"30px",
  background: '#FFFFFF',
  boxShadow: '-2px 2px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '8px',
  '@media (max-width: 576px)': {
  marginBottom:"30px",
  background: '#FFFFFF',
  boxShadow: '-2px 2px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '8px',
  width:"100%",
  }, 
  
},
'& .wrapper-question-text-question': {
  fontSize : "22px",
  fontWeight: 600,
  letterSpacing: '0.43px',
  lineHeight: "30.14px",
  color:"#212121",
  fontFamily:"var(--button-font)",
  textAlign:"left",
  paddingLeft:"29px",
  paddingTop:"18px",
  paddingBottom:"18px",
  '@media (max-width: 576px)': {
    fontSize : "12px",
    fontWeight: 400,
    letterSpacing: '0.43px',
    lineHeight: "18px",
    color:"#212121",
    fontFamily:"var(--button-font)",
    // textAlign:"left",
    paddingTop:"3px",
    paddingRight:"10px",
    paddingBottom:"3px",
    width:"90%",
    paddingLeft:"3px",
    textAlign:"justify",
   },
  },
'& .wrapper-question-text-ans': {
fontFamily: 'var(--button-font)',
fontSize: '17px',
fontWeight: 400,
lineHeight: '31px',
letterSpacing: '0.43160051107406616px',
textAlign: 'left',
color:"#212121",
paddingLeft:"26px",
paddingRight:"35px",
'@media (max-width: 576px)': {
  position:"relative",
  fontFamily: 'var(--button-font)',
  fontSize: '10px',
  fontWeight: 300,
  lineHeight: '22px',
  letterSpacing: '0.43160051107406616px',
  textAlign: 'justify',
  color:"#212121",
  paddingLeft:"12px",
  paddingRight:"35px",
width:"100%"  },    
},
'& .plusImg': {
  
  '@media (max-width: 576px)': {
   width:"10px",
    height:"10px"
  
  },    
  },
'& .wrapper-icon': {
transform: "rotate(45deg)"
},

})
////////////////////////////////////////////////////////////////////////////////////////////

const Questions = function ControlledAccordions({questions}: {questions: {id: number, question: string, ans: string}[]}) {
  const [expanded, setExpanded] = React.useState< string | number | false>(false);
  const handleChange =
  (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <BannerWrapper>
    <div className='wrapper-question-box'>
    {questions.map((item)=>(
      <Accordion key={item.id} expanded={expanded === item.id} onChange={handleChange(item.id)} className='wrapper-question-text-box ' >
        <AccordionSummary
          expandIcon={<img src={plus} alt="img" className='plusImg'/>}
          aria-controls="panel1bh-content"
          id="panel1bh-header">
        <Typography sx={{ color: 'text.secondary' }} className='wrapper-question-text-question'>{item.question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='wrapper-question-text-ans'>
          {item.ans}
          </Typography>
        </AccordionDetails>
      </Accordion>
      )
      )}      
      </div>
    </BannerWrapper>
  );
}
export default Questions;


