import React from "react";
import { styled, Box } from "@mui/material";

const CardWrapper = styled(Box)({
  display: "flex",
  paddingBottom:"1em",
  "@media (max-width: 576px)": {
    // flexDirection: "column",
  },

  "& .wrapper-text-container": {
    marginTop: "66px",
    // height: "610px",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    marginLeft: "auto",
    marginRight: "auto",    
    "& h2": {
      color: "var(--primary-color)",
      textAlign: "left",
      
      fontSize: "4rem",
      fontWeight: 700,
      marginLeft: "auto",
      marginRight: "auto",
      // padding: "20px 0px",
    },
    "& p": {
      color: "var(--subtext-color)",
      fontSize: "1.5em",
      fontWeight: "400",
      padding:"1em",
      maxWidth: '960px',
      margin: 'auto',
      fontFamily:"var(--button-font)",
      textAlign:"justify",
    },
    // "& h2": {
    //   color: "black",
    //   fontFamily: "Poppins",
    //   fontSize: "18px",
    //   fontWeight: 400,
    //   lineHeight: "42px",
    //   width: "1068px",
    //   padding: "50px 80px",
    //   marginLeft: "auto",
    //   marginRight: "auto",
    // },
    "@media (max-width: 576px)": {
      marginTop: "36px",
      // height: "610px",
      
      width: "100%",
      padding:"0px 20px",
      "& h2": {
        color: "var(--primary-color)",
        textAlign: "left",
        fontSize: "2.5rem",
        fontWeight: 700,
      },
      "& p": {
        color: "var(--subtext-color)",
        fontSize: "1em",
        fontWeight: "400",
        padding:"1em",
        fontFamily:"var(--button-font)",
        textAlign:"justify",
      },
      // "& h2": {
      //   color: "black",
      //   fontFamily: "Poppins",
      //   fontSize: "12px",
      //   fontWeight: 400,
      //   lineHeight: "24px",
      //   width: "100%",
      //   padding: "25px 30px",
      //   marginLeft: "auto",
      //   marginRight: "auto",
      // },
    },
  },
  "& .wrapper-photo-container": {
    display: "flex",
    justifyContent: "space-evenly",
 
    padding: "50px 0px",
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: 'center',
    "& span": {
      fontFamily: "var(--button-font)",
        fontSize: "1.9rem",
        fontWeight: 400,
        width: "100%",
      textAlign:"center",
      color: "var(--subtext-color)",
    },
    "@media (max-width: 576px)": {
      display: "flex",
      flexDirection:"column",
      width: "60%",
      justifyContent: "space-evenly",
      padding: "25px 0px",
      marginLeft: "auto",
      marginRight: "auto",
      "& span": {
        fontFamily: "var(--button-font)",
        fontSize: "1.2rem",
        fontWeight: 400,
        width: "100%",
      textAlign:"center",
      color: "var(--subtext-color)",
      padding:"20px 0px"

        
      },
      "& .img": {
        width: "70px",
        height: "70px",
        "@media (max-width: 576px)": {
          
          width: "30px",
        height: "30px",
        }
      },
    },
  },
});

const GetToKnowUs = () => {
  return (
    <CardWrapper>
      <div className="wrapper-text-container">
        <h2 className="section__title">WE ARE COACH CORNER</h2>
        <p>
          At Coach Corner, we are a communication and strategy platform designed specifically 
          for sports teams and coaches. We provide cutting-edge tools and features to help teams 
          communicate and strategize more effectively, including team management, performance analysis, 
          and personalized coaching. Our platform is designed to empower coaches and teams to optimize 
          their performance and achieve their goals through streamlined and intuitive communication 
          and strategy tools. At Coach Corner, we are committed to helping sports teams and 
          coaches succeed by providing them with the tools they need to communicate, 
          collaborate, and perform at their best.
        </p>
      </div>
    </CardWrapper>
  );
};

export default GetToKnowUs;
