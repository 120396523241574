import {Box,styled } from "@mui/material";


const HashTag = styled('div')({
    marginTop: '0.1em',
    backgroundImage: 'url(https://campo.bold-themes-cdn.com/demo-01/wp-content/uploads/sites/2/2022/11/top_cover_04_home_03.png)',
    backgroundColor:"var(--primary-color)",
    backgroundPosition: 'top center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
})

const SectionWrapper = styled(Box)({
    padding: "100px 0px",
    textAlign: "center",
    "& .contant-container":{
        padding:"0px 20px",
        maxWidth:"1280px",
        display:"flex",
        margin:"auto",
        "@media (max-width:700px)": {
           flexDirection:"column",
           maxWidth:"100%",
            },
    },
    "& .padding-left":{
        paddingLeft:"2.7rem",
        paddingTop:"1rem",
        textAlign:"left",
        "@media (max-width:700px)": {
            paddingLeft:"0rem",
        paddingTop:"1rem",
        textAlign:"center",
             },
    },
    "& .flex-half":{
        width:"50%",
        alignItems:"center",
        "@media (max-width:700px)": {
            width:"100%",
             },
    },
    "& .first":{
            fontSize:"1.3rem",
            display: 'block',
            color:"white",
            margin:"0px",
            "@media (max-width:700px)": {
                fontSize:"1rem",
        },},
    "& .second":{
        display: 'block',
        fontSize:"6rem",
        color:"white",
        margin:"0px",
        fontFamily:"var(--heading-font)",
        "@media (max-width:700px)": {
            fontSize:"3rem",
    },
},
"& .font-size":{
   
    "& .second":{
        fontSize:"2.5em",
        display: 'block',
        "@media (max-width:700px)": {
            fontSize:"2.7rem",
    },
    },
    "& .first":{
        fontSize:"2em",
        display: 'block',
        fontFamily:"var(--button-font)",
        fontWeight:"600",
        "@media (max-width:700px)": {
            fontSize:"1rem",
    },
    }
},
    "& .justify":{
        justifyContent:"space-evenly",
        "@media (max-width:700px)": {
            justifyContent:"space-evenly"
        },
    }
    
    
    
  });
  const GoEagles = () => {
    return (
      <section className="index-section hashtag-section" id="hastag-section-1">
        <HashTag>
            <SectionWrapper>
                <div className="contant-container">
                    <div className="flex-half flex-box">
                        <div className="section__title_secondary">
                            <span className="first">Join Our Community</span>
                            <span className="second">The Coach Corner</span>
                        </div>
                    </div>
                        <div className="flex-half font-size">
                            <div className="flex-box justify">
                                <div className="padding-left">
                                    <span className="second">12+</span>
                                    <span className="first">Organizations</span>
                                </div>
                                <div className="padding-left">
                                    <span className="second">300+</span>
                                    <span className="first">Players</span>
                                </div>
                            </div>
                            <div className="flex-box justify">
                                <div className="padding-left">
                                    <span className="second">25+</span>
                                    <span className="first">Certified Coaches</span>
                                </div>
                                <div className="padding-left">
                                    <span className="second">10+</span>
                                    <span className="first">Sports</span>
                                </div>
                            </div>
                    </div>
                </div>        
            </SectionWrapper>
        </HashTag>
      </section>
    );
  };
  
  export default GoEagles;
  