import React from 'react';
import { styled, Box } from '@mui/material';
import IconsCard from './Cards/IconCards';

const BackGround = styled(Box)({
    backgroundImage:`url("https://res.cloudinary.com/ddkdvh5ps/image/upload/v1678020845/banner_lgzyd4.jpg")`,
    backgroundSize:"cover",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    "@media(max-width:768px)": {
      width:"100%",
     },
     "& .full__width":{
      width: '100%'
     },
    "& .card__main":{
      display: 'flex',
      justifyContent: 'center',
      margin:"auto",
      width:"100%",
      padding: '1em',
      background: '#13376694',
      "@media(max-width:768px)": {
        padding: '1em',
       },
}
})


const CardContainer = styled(Box)({
        padding: '2em 1em',
        display:"flex",
        justifyContent:"flex-start",
        margin:"auto",
        gap: '1em',
        maxWidth:"1200px",
        "@media (min-width:769px)":{
          flexWrap: 'wrap',
        },
        "@media(max-width:768px)": {
          padding: '1em',
          width:"100%",
          overflowX: 'scroll',
          scrollSnapType: 'x mandatory',
          "&  div.card":{
            scrollSnapAlign: 'center'
          }    
         },
        
})

const CardWrapper = styled(Box)({
        paddingLeft:"3em",
        // width:"60%",
        maxWidth:"1440px",
        textAlign:"center",
        boxShadow: "none",
        borderRadius: "4px",
        background: 'transparent',
        "@media(max-width:768px)": {
          width:"99%",
          paddingLeft:"0em"
         },
        
         "& h2":{ 
          textAlign:"left",
          color: 'white', 
          fontWeight:"900",
          fontSize: '6.5rem',
          margin:'0px 0px 30px 0px',
          "@media(max-width:768px)": {
            fontSize: "2.5rem",
           },
          // margin: '0px 0px 15px 0px',
          "& span":{
            color:"var(--secondary-color)"
          }
        },
         "& p":{ 
            fontFamily: "var(--button-font)",
            fontWeight: "500",
            fontSize: "1.1em",
            color: "#fff",
            textAlign:"left",
            width:"60%",
            "@media(max-width:768px)": {
              width:"100%"
             },
        },
         "& .button-container":{
            display:"flex", 
            justifyContent:"flex-start",
            "@media(max-width:768px)": {
              flexDirection: "column",
              width:"99%"
             },
            "& .margin-r-20":{
                marginRight:"20px",
                "@media(max-width:768px)": {
                  margin:"20px 0px",
                  alignItems:"center"
                 },
                
            }
         },
})

const BannerSection2 = () => {
  return (
    <section className='index-section hero-banner' id='hero-banner-2'>
      <BackGround>
        <div className='card__main' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
          <div className='full__width'>     
            <CardWrapper>
              <div className='card__item'>
                  <h2 className='section__title'><span>Game</span><br/>Changing Features</h2>
                  <div className='rte rte-settings card__content'>
                    <p>
                    Take Your Coaching Game to the Next Level with These Must-Have Features<br/>
                    loaded with game-changing features designed to help you and your team succeed.
                    </p>
                  </div> 
              </div>
              <div className='button-container card__item'>
                <button className='btn btn--primary btn--half margin-r-20'>JOIN ACADEMY</button>
                <button className='btn btn--secondary--outline btn--half'>READ TESTIMONIALS</button>
              </div>
            </CardWrapper>
            <CardContainer>
                <IconsCard 
                  title={'Communication'} 
                  content={'Coaches and players can use the platform to send messages, share files, and schedule events.'}
                  />
                <IconsCard 
                  title={'Play drawing'} 
                  content={'Coaches can use the built-in drawing tool to create and share plays with their team.'}
                  />
                <IconsCard 
                  title={'Game planning'} 
                  content={'Coaches can use the platform to plan their games, set up lineups, and assign tasks to players.'}
                  />
                <IconsCard 
                  title={'Video analysis'} 
                  content={'Coaches can upload videos of games or practices, and use the platform to analyze them and provide feedback to players.'}
                  />
            </CardContainer>
          </div> 
        </div>
      </BackGround>
    </section>
  )
}

export default BannerSection2


