import styled from '@emotion/styled';
import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import AddLocation from '@mui/icons-material/AddLocation';
import YouTube from '@mui/icons-material/YouTube';
import Twitter from '@mui/icons-material/Twitter';
import Facebook from '@mui/icons-material/Facebook';
import { Instagram } from "@mui/icons-material";


const ContactSection = styled('section')({
    background: 'var(--primary-color)',
    color: '#fff',
    padding: '2em 5em',
    width: '100%',
    borderBottom: '1px solid #fff',
    "@media(max-width:768px)":{
        padding: '2em 1em'
    },
    "& .main__content":{
        background: '#fafafa',
        color: '#000000',
        maxWidth: '700px',
        padding:'1em',
        dispaly: 'flex',
        alingItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        border: '3px solid var(--secondary-color)',
        borderRadius: '8px',
        "& .content__body":{
            "& h2,h4":{
              textTransform: 'capitalize'         
            }
        },
        "& li":{
            padding:"3px 0px",
            marginBottom: '10px',
          },
          "& .social-media":{
            display:"flex",
            alignItems:"center",
            "& .list__flex":{
              display:"flex",
              alignItems:"center",
              gap: '0.5em',
              "&.address-link":{
                alignItems: 'start'
              },
              "&.social--links":{
                gap: '0.5em'
              }
            },
          },
    }
})


function Contact(){
    return(
        <ContactSection className='contact--section'>
          <div className="main__content">
            <div className="content__body">
                <h2 className='title h1'>Have questions about our pricing plans?</h2>
                <h4 className='body--content'>Get in touch with our support team.</h4>
                <div  className='contact--informations'>
                    <ul>
                    <li className="social-media">
                        <a href="tel:+1(202)4365839" target='_blank' className="list__flex">
                        <PhoneIcon/><span>+1 (202) 4365839</span> 
                        </a>
                    </li>
                    <li className="social-media">
                        <a href="mailto:info@thecoachcorner.com" target='_blank' className="list__flex">
                        <EmailIcon/><span>info@thecoachcorner.com</span>
                        </a>
                    </li>
                    <li className="social-media">
                        <span className="list__flex social--links">
                        <a href="#" target='_blank'>
                        <Facebook/>
                        </a>
                        <a href="https://twitter.com/TheCoachsCorne3" target='_blank'
                            title="The Coach Corner on Twitter">
                        <Twitter/>
                        </a>
                        <a href="#" target='_blank'>
                        <YouTube/>
                        </a>
                        <a href="https://www.instagram.com/coachcorner__/" target='_blank' 
                            title="The Coach Corner on Instagram">
                        <Instagram/>
                        </a>
                        </span>
                    </li>
                    <li className="social-media">
                        <a href="https://goo.gl/maps/JGuk43sfUKz4u7g78"  target='_blank' 
                        className="list__flex address-link">
                        <AddLocation/><span>P/O Box 45832, Baton Rouge, LA 70895</span>
                        </a>
                    </li>
                    </ul>
                </div>
            </div>
          </div>
        </ContactSection>
    )
}

export default Contact