import React from 'react'
import {styled, Box, Container} from "@mui/material"


const SectionWrapper = styled(Box)({
     // marginTop:"40px",
})


const BoxWrapper = styled(Box)({
      width: '100%',
      maxWidth: '1000px',
      margin: 'auto',
      textAlign:"left",
      alignContent:"left",
      "& h1":{
        textAlign: 'left',
        marginBottom: '50px'
      },
      "& h2":{
        fontSize:"2rem",
        fontWeight:"900",
      }
})


const PrivacyPolicyComponent = () => {
  return (
    <SectionWrapper>
      <Container>
        <BoxWrapper>
          <h1 className='section__title mr-0'>Privacy Policy</h1>
          <div className='rte rte-settings'>
            <p>
              At Coach Corner, we are committed to protecting the privacy and security of our users. 
              This privacy policy outlines the types of information we collect, 
              how we use and protect that information, 
              and your rights and options regarding your personal data.
            </p>
            <h2>Information We Collect</h2>
            <p>
              We may collect personal information from you when you create an account, 
              use our services, or contact us. This information may include your name, 
              email address, phone number, and other contact information. 
              We may also collect usage data, such as your IP address, browser type, 
              and device information.
            </p>
            <h2>How We Use Your Information</h2>
            <p>
              We use your personal information to provide you with our services, 
              communicate with you about your account, and respond to your inquiries. 
              We may also use your information for marketing purposes, 
              such as sending you promotional offers or newsletters, but we will only do so with your consent.
            </p>
            <p>
              We do not sell or share your personal information with 
              third parties for their own marketing purposes. However, we may share your 
              information with our service providers, such as our hosting or analytics providers, 
              to help us operate and improve our services.
            </p>
            <h2>How We Protect Your Information</h2>
            <p>
              We take reasonable measures to protect your personal 
              information from unauthorized access, use, or disclosure. We use industry-standard 
              security measures, such as encryption and firewalls, to protect your data. However, 
              no method of transmission over the internet or electronic storage is completely secure, 
              and we cannot guarantee the absolute security of your data.
            </p>
            <h2>Your Rights and Options</h2>
            <p>
             You have the right to access, modify, or delete your personal information at any time. 
             You can do so by logging into your account or contacting us directly. 
             You also have the right to withdraw your consent to our use of your information for 
             marketing purposes at any time.
            </p>
            <h2>Changes to this Privacy Policy</h2>
            <p>
             We may update this privacy policy from time to time to reflect changes in our services or 
             legal requirements. We will post the updated policy on our website and notify you of any 
             significant changes. We encourage you to review this policy regularly to stay informed about 
             our practices.
             </p>
             <h2>Contact Us</h2>
             <p>
              If you have any questions or concerns about this privacy policy or our data practices, 
              please contact us at <a href='mailto:info@thecoachcorner.com'>info@thecoachcorner.com</a>.
              </p>
            </div>
        </BoxWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default PrivacyPolicyComponent