import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {styled  } from '@mui/material';
import { Link } from 'react-router-dom';
const CardWrapper = styled(Card)({
      flexBasis: '32%',
      height:"500px",
      background: "linear-gradient(to bottom right, rgb(9, 32, 62), rgba(9, 32, 62, 0.5))",
      // backgroundBlendMode: 'multiply',
      "@media(max-width:768px)":{
        flexBasis: '100%',
        height: '400px'
      },
      fontFamily:"var(--heading-font)",
      "& .footer-text-cont":{
          position:"relative",
          marginTop:"-150px",
          display:"flex"
      },
"& .section__heading":{
    display:"flex",
    alignItems:"end",
    justifyContent:"space-between",},
    
    "& .card__item":{
        display:"flex",
        justifyContent:"left"
    },
    "& .left-side-content":{
        fontFamily:"var(--heading-font)",
        padding:"10px 10px 10px 0px",
        color:"var(--secondary-color)",
        // fontSize:"2.5rem"
    },
    "& .text-cont__right":{
        display:"flex",
        flexDirection:"column",
        color:"var(--secondary-color)"
    },
    "& .top-text":{
        fontSize:"1.5rem",
        color:"white"
    },
    "& .bottom-text":{
        fontSize:".8rem",
        color:"white"
    },
        
            "& .image-hover":{
                
                filter:"brightness(70%)"


            },
            "& .image-hover:hover":{
            

            }
})
export default function RosterCard({img}) {
  return (
    <CardWrapper className='roster__card'>
      <CardMedia
        className='image-hover'
        component="img"
        alt="green iguana"
        height="100%"
        image={img}
        loading="lazy"
      />
      <CardContent>
        <div className='footer-text-cont'>
          <div className="left-side-content"><h2 className='left-side-content'> 03</h2> </div>
               <h2 className='section__title' > 
               <span className='text-cont__right'>
               <span className='top-text'>MORGAN</span>  <span>WALKING </span>  <span className='bottom-text'>Right Gaurd </span></span></h2>
        </div>
      </CardContent>
      
    </CardWrapper>
  );
}