import React from 'react';
import './App.css';
import 'aos/dist/aos.css';
import './assets/js/script.js';
import { Routes, Route, useLocation } from 'react-router-dom';
import NewHeader from './Common/NewHeader';
import HomeTest from './Components/HomeTest/Index';
import Footer from './Common/Footer';
import IndexAboutUs from './Components/AboutUs/IndexAboutUs';
import SignupPage from './Components/SignUpOld/SignupComponent/SignupPage';
import LoginPage from './Components/Login/LoginComponent/LoginPage';
import PrivacyPolicyPage from './Components/PrivacyPolicy/Index';
import IndexFAQ from './Components/FAQ/IndexFAQ';
import Contactus from './Components/ContactUsNew/Index';
import Features from './Components/Features/Features';
import Pricing from './Components/Pricing/Pricing';
import PageNotFound from './Common/PageNotFound';





function App() {
    let location = useLocation();
    let path = location.pathname;

    return (
        <div className="App" data-page={path}>
         <NewHeader />
            <Routes>
                <Route path='/' element={<HomeTest />} />
                <Route path='/pages/features' element={<Features/>}/>
                <Route path='/pages/pricing' element={<Pricing/>}/>
                <Route path="/account/login" element={<LoginPage/>}/>
                <Route path="/account/register" element={<SignupPage/>}/>
                <Route path="/pages/faqs" element={<IndexFAQ/>}/>
                <Route path="/pages/privacy-policy" element={<PrivacyPolicyPage/>}/> 
                <Route path="/pages/contact-us" element={<Contactus/>}/> 
                <Route path="/pages/about-us" element={<IndexAboutUs/>}/> 
                <Route path='*' element={<PageNotFound/>}/>
            </Routes>
           <Footer/>
        </div>
    );
}

export default App;
