import React from "react";
import { Link,} from "react-router-dom";
import { Box, Grid, styled, Typography } from "@mui/material";
import { HiOutlineBars3 } from "react-icons/hi2";
import HeaderSidebar from "./HeaderSidebar";
import {useState} from "react";
import '../assets/css/header.css';
import { backgroundColor, font } from "./Veriables";



export const ButtonWrapper = styled("div")({
  "& .side-bar-button__header": {
    display: "none",
    "@media (max-width: 576px)": {
      display: "flex",
      justifyContent:"right",
      position:"absolute",
      right:"15px",
      top:"50px"
      // width:"100px"
    },
  },
});
export const HeaderWrapper = styled(Box)({
  position: "relative",
  background:'var(--primary-color)',
  zIndex:"10",
  width: "100%",
  boxShadow: "0 3px 3px rgba(0, 0, 0, 0.15)",
  "@media (max-width:700px)": {
    background: "",
  },
  "& .header_item_primary": {
    "@media (max-width:700px)": {
      display: "none",
    },
  },
  "& .header_item_secondary": {
    "@media (max-width:700px)": {
      background: "",
    },
  },
  "& .header__main-wrap":{
    justifyContent:"center" 
  },
  "& .header-logo": {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    "@media (max-width:700px)": {
      maxWidth:"300px"
   },
  },
  "& .header__logo-wrapper":{
    maxHeight: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  "& .search-field-container":{
    display:"",
    
    "@media (max-width:700px)": {
      display:"none"
    },
  },
  "& .logo-img": {
    maxWidth: "100%",
    display: 'block'
  },
  "& .span-class":{
    fontFamily:"var(--button-font)",
    fontWeight:"900",
    color:'rgb(68 119 183)',
    fontSize:"2em",
    marginLeft:"-30px",
    "@media (max-width:700px)": { 
      display:"none"
    },
  }
});

export const MenuItem = styled(Typography)({
  margin:"0px 0.8em",
  fontFamily: 'Exo',
  fontWeight: 600,
  fontSize: ".9em",
  fontStyle: "normal",
  letterSpacing: "1px",
  color: 'white',
  "& a": {
    color: "inherit",
    "&:hover": {
      color: "inherit !important",
      opacity: "0.8",
    },
  },
});

export const MenuItemSecondary = styled(Typography)({
  fontFamily:'var(--primaryFont)',
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "1.2em",
  letterSpacing: "1px",
  marginLeft: "20px",
  color: 'white',
  "& a": {
    color: "inherit",
    "&:hover": {
      color: "inherit !important",
      opacity: "0.8",
    },
  },
  "@media (max-width: 576px)": {
    marginLeft: "20px",
    fontSize: "11px",
  },
});

export const CreateEventButton = styled(Typography)({
  position: "relative",
  fontFamily:'var(--primaryFont)',
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "1.2em",
  letterSpacing: "1px",
  lineHeight: "19px",
  letterSpacing: "0.352577px",
  color: '#000000',
  "& svg": {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    top: "-25px",
  },
  "@media (max-width: 576px)": {
    fontSize: "11px",
  },
});

export const Sidebar = styled(Box)({
  position: 'fixed',
  display: 'flex',
  width: '100%',
  height: '100%',
  transition: "all 0.5s ease !important",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: backgroundColor,
  zIndex: 5,
  cursor: 'pointer',
  overFlow:"hidden",
 
  "& .overlay": {
    position: 'fixed',
    width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overFlow:"hidden",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 5,
    '& nav': {
      fontFamily:font,
      fontSize: '18px !important',
      fontWeight: '600 !important',
      width: '85%',
      height: '100%',
      background: backgroundColor,
      right: "0",
      position: "absolute",
      '& .top-div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '100px',
        width: '100%',
        borderBottom: '3px solid #A8A8A8',
        backgroundColor: backgroundColor,
      },
      '& .sidebar-footer': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '100px',
        position:"absolute",
        width: '100%',
        borderTop: '3px solid #A8A8A8',
        borderBottom: '3px solid #A8A8A8',
        backgroundColor: backgroundColor,
      },
      '& ul': {
        height: '100%',
        width: '100%',
        listStyle: 'none',
        padding: '0px',
        marginTop:"30px",
        '& li': {
          minHeight: '60px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: '10px 12px',
          '& li:hover': {
            background: 'rgba(0, 0, 0, .1)',

          },
         '& a': {
            position: 'relative',
            color: "var(--primary-color)",
            fontFamily:"var(--button-font)",
            textDecoration: 'none',
            fontSize: '20px',
            fontWeight: '500',
            display: 'block',
            width: '100%',
            textAlign: "left",
          },
          '& a:hover': {
            background: 'rgba(0, 0, 0, .1)',

          },

          '& a.click span::before': {
            content: '"\f068"',
          },
        },
      },
    },

    "& .closebtn": {
      // position: "static",
      border: "none",
      background: "#36454F",
      height: "60px",
      width: "15%",
      padding: "10px",
      position:"absolute",
      right:"85%",
      "& i": {
        // color: "white",
        fontSize: "35px",
        textStroke: '1px black',
      }
    }
  }
})

function NewHeader() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  ////////////////////////////////////sidemenu open close logic///////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const openSidebar = () => {
      const sidebarMenu = document.getElementById("slide");
      if (sidebarMenu)    {sidebarMenu.style.transition = "all 0.9s ease";
                          sidebarMenu.style.transform = "translateX(0%)";
                          setIsSidebarOpen(true);}}
  const closeSidebar = () => {const sidebarMenu = document.getElementById("slide");
  if (sidebarMenu)    {sidebarMenu.style.transition = "all 0.9s ease";
                      sidebarMenu.style.transform = "translateX(100%)";
                      setIsSidebarOpen(true);}}
return (
    <header className="header">
      <HeaderWrapper>
      <Sidebar id="slide"  style={{transform:"translateX(100%)"}}>
                {isSidebarOpen && <HeaderSidebar isOpen={isSidebarOpen} close={closeSidebar} />}</Sidebar>
       <Grid
            container
            className='header__main-wrap'>
            <Grid
              item
              sm={3.3}
              className="header-logo"
              style={{ textAlign: "left"}}
            >
              <Link to="/" className="header__logo-wrapper">
                <img src="https://res.cloudinary.com/ddkdvh5ps/image/upload/v1678021890/logo_oafrmv.png" 
                     alt="coach corner logo"
                     className="logo-img" 
                     height='100'
                     loading="lazy"/>
              </Link>
              <Link to="/">
                <span className="span-class">Coach Corner</span>
              </Link>
            </Grid>
            <Grid item sm={1.5} className="header_item_primary">
              <Box
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "left",
                }}
              >
                
                </Box>
            </Grid>
            <Grid
              item
              sm={7}
              className="header_item_primary"
              style={{ textAlign: "right" }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* <MenuItem >
                  <Link to="/pages/about-us">
                  About Us
                  </Link>
                </MenuItem> */}
                <MenuItem >
                  <Link to="/pages/features">
                    Features
                  </Link>
                </MenuItem>
                <MenuItem >
                  <Link to="/pages/pricing">
                    Pricing
                  </Link>
                </MenuItem>
                <MenuItem >
                  <Link to="/pages/about-us">
                    About us
                  </Link>
                </MenuItem>
                <MenuItem >
                  <Link to="/pages/contact-us">
                    Contact us
                  </Link>
                </MenuItem>
                <MenuItem >
                  <Link to="/account/login">
                    Login
                  </Link>
                </MenuItem>
                 <button className='btn btn--primary btn--half'>
                  <Link to="/account/register">Register</Link></button>
                
               
              </Box>
            </Grid>
            <Grid item sm={1} >
              <Box
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "left",
                }}
              >
                <MenuItemSecondary>
<ButtonWrapper>
  <HiOutlineBars3
    size={30}
    className="side-bar-button__header"
    
    onClick={openSidebar}
  />
</ButtonWrapper>
</MenuItemSecondary>
                
                </Box>
            </Grid>
          </Grid>
      </HeaderWrapper> 
    </header>
  );
}

export default NewHeader;
