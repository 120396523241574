import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { Box ,styled } from '@mui/material'
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
/////////////////////////////////////////////////////////////////////////////////////////////
const BannerWrapper = styled(Box)({
  margin:"auto",
  border: '0',
  maxWidth:"1024px",
  '@media (max-width: 576px)': {
    position:"relative",
    width:"100%",  
 },    
  '& .wrapper-question-box': {
  '@media (max-width: 576px)': {
        position:"relative",
        width:"100%",  
        minHeight:"70px",
        marginBottom:"20px" ,
        marginLeft:"auto",
        marginRight:"auto"   
      },    
},
'& .wrapper-question-text-box': {
  
  marginBottom:"1em",
  background: '#FFFFFF',
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
  borderRadius: '8px',
  border: '0',
  '@media (max-width: 576px)': {
    width:"100%",
  }, 
  "&::before":{
    content:'none',
  },
},
'& .wrapper-question-text-question': {
    fontSize : "22px",
    fontWeight: 600,
    color:"#000",
    fontFamily:"var(--button-font)",
    textAlign:"left",
    padding: '0.3em 1em',
    "& .accordian--main":{
      margin: '0',
      "& > div":{
        margin: '0'
      },
      "&::before":{
        content: 'none'
      }
    },
    '@media (max-width: 576px)': {
      fontSize : "12px",
      fontWeight: 400,
      letterSpacing: '0.43px',
      lineHeight: "18px",
      fontFamily:"var(--button-font)",
      padding: '0.5em 1em'
    },
  },
'& .wrapper-question-text-ans': {
    fontFamily: 'var(--button-font)',
    fontSize: '16px',
    fontWeight: 500,
    textAlign: 'left',
    color:"#000",
    padding: '0em 1em',
    '@media (max-width: 576px)': {
      position:"relative",
      fontSize: '14px',
    },    
},
'& .plusImg': {
    width:"30px",
    height:"30px"
},
'& .wrapper-icon': {
   transform: "rotate(45deg)"
},

})
////////////////////////////////////////////////////////////////////////////////////////////

const Questions = function ControlledAccordions({questions}) {
  const [expanded, setExpanded] = useState(false);
  const handleChange =
  (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <BannerWrapper className='faqs--main'>
    <div className='wrapper-question-box'>
    {questions.map((item)=>(
      <Accordion key={item.id} expanded={expanded === item.id} onChange={handleChange(item.id)} 
      className='wrapper-question-text-box ' >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className='plusImg'/>}
          aria-controls="panel1bh-content"
          id="panel1bh-header" className='accordian--main'>
        <Typography sx={{ color: 'text.secondary' }} 
        className='wrapper-question-text-question'>{item.question}</Typography>
        </AccordionSummary>
        <AccordionDetails className='accordian--content'>
          <Typography className='wrapper-question-text-ans'>
          {item.ans}
          </Typography>
        </AccordionDetails>
      </Accordion>
      )
      )}      
      </div>
    </BannerWrapper>
  );
}
export default Questions;


