import React from 'react'
import sideImg from "../../../images/images/img7.jpg"
import {Button, Card, styled} from '@mui/material'
import logo from "../../../../src/logo.png"
import facebook from "../SignupComponent/images/facebook.png"
import apple from "../SignupComponent/images/apple.png"

import {AiOutlineEyeInvisible, AiOutlineEye} from "react-icons/ai"
import {MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank} from "react-icons/md"
import {useState} from "react"

//////////////////////////////////////////////////////////////////////////////////////////////////
const CardWrapper = styled(Card)({
  display: "flex",
  position: "relative",
  flexDirection: "row",
  // paddingTop: "95px",
  borderRadius: "0px",
  border: "none",
  boxShadow: "none",
  fontFamily: "var(--button-font)",
  "@media (max-width: 576px)": {
    flexDirection: "column",
  },
  "& .left-side":{
    width:"800px",
    height:"700px",
    "@media (max-width: 576px)": {
      width: "100%",
      height:"100%"
    },
  },
  "& .right-side":{
    // flex:"50%"
  },
  "& .wrapper-img": {

    "@media (max-width: 576px)": {
      width: "100%",
    },
  },
  "& .wrapper-form-heading": {
    // padddingTop: "95px",
    position: "relative",
    marginLeft: "58px",
    top: "50px",
    "@media (max-width: 576px)": {
      textAlign: "canter",
      margin: "auto",
    },
  },
  "& .wrapper-logo-heading": {
    textAlign: "left",
    display:"flex",
  
    fontSize:"45px",
    alignItems:"center",
    width:"100%",
    "& img":{
      width:"150px",
      marginLeft:"-40px"
    },
    "& .heading__login":{
      display:"flex",
      
      height:"100%"
    },
    "@media (max-width: 576px)": {
      textAlign: "center",
    },
  },
  "& .wrapper-form": {
    textAlign: "left",
    "@media (max-width: 576px)": {
      paddingLeft: "20px",
    },
  },
  "& .wrapper-email-text": {
    fontFamily: "var(--button-font)",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "19px",
    letterSpacing: "0.43160051107406616px",
    textAlign: "left",
    color: "#1E1E1E",
    marginTop: "40px",
    marginBottom: "7px",
    paddingLeft: "3px",
    "@media (max-width: 576px)": {},
  },
  "& .wrapper-input-box": {
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 20px",
    gap: "10px",
    position: "absolute",
    width: "100%",
    height: "47px",
    border: "1px solid rgba(91, 91, 91, 0.5)",
    borderRadius: "8px",
    "@media (max-width: 576px)": {
      justifyContent: "left",
      alignItems: "left",
      padding: "14px 20px",
      gap: "10px",
      position: "absolute",
      width: "90%",      height: "47px",
      border: "1px solid rgba(91, 91, 91, 0.5)",
      borderRadius: "8px",
    },
  },
  "& .wrapper-visible-btn": {
    display: "flex",
    justifyContent: "right",
    alignItems: "right",
    padding: "14px 20px",
    gap: "10px",
    position: "absolute",
    right: "0px",
    height: "47px",
    borderRadius: "40px",
    "@media (max-width: 576px)": {
      // width:"300px",
      right: "20px",
    },
  },
  "& .wrapper-input-box-signup": {
    // display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 20px",
    gap: "20px",
    position: "absolute",
    marginTop: "10px",
    width: "100%",
    height: "47px",
    border: "1px solid rgba(91, 91, 91, 0.5)",
    borderRadius: "40px",
    background: "var(--secondary-color)",
    fontFamily: "var(--button-font)",
    fontSize: "14px",
    color: "#FCFCFC",
    fontWeight: "600",

    "@media (max-width: 576px)": {
      width: "90%", 
      
    },
  },

  "& .wrapper-fb-apple-icons": {
    textAlign: "center",
    justfyCntent: "center",
    width: "100%",
    marginTop: "80px",
    "@media (max-width: 576px)": {
      postion: "absolute",
      marginTop: "105px",
      marginBottom: "75px",
      width: "90%", 
    },
  },
});
////////////////////////////////////////////////////////////////////////////////////////////////////



/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
const SignupPage = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [checkBox, setCheckBox] = useState<boolean>(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // send login request to server
  };
  return (
    <CardWrapper>
      <div className='left-side'>
        <img src="https://res.cloudinary.com/ddkdvh5ps/image/upload/v1678021645/img7_em3fba.jpg" alt="img" className="wrapper-img" loading="lazy"/>
      </div>
      <div className='right-side'>
        <div className="wrapper-form-heading">
          <div className="wrapper-logo-heading">
            <img src="https://res.cloudinary.com/ddkdvh5ps/image/upload/v1678021890/logo_oafrmv.png" alt="logo" />
            <span className='section__title'>COACH CORNER</span>
            
          </div>
          <div className="wrapper-form">
            <form onSubmit={handleSubmit}>
              <p className="wrapper-email-text">Email</p>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your email"
                className="wrapper-input-box"
              />
              <br />
              <p className="wrapper-email-text">Password:</p>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                placeholder="Enter your password"
                className="wrapper-input-box"
              />
              <span
                className="wrapper-visible-btn"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <AiOutlineEye size={20} />
                ) : (
                  <AiOutlineEyeInvisible size={20} />
                )}
              </span>
              <br />
              <p className="wrapper-email-text">Confirm Password:</p>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                placeholder="Enter your password"
                className="wrapper-input-box"
              />
              <span
                className="wrapper-visible-btn"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <AiOutlineEye size={20} />
                ) : (
                  <AiOutlineEyeInvisible size={20} />
                )}
              </span>

              <br />
              <p
                className="wrapper-email-text"
                onClick={() => setCheckBox(!checkBox)}
              >
                {checkBox ? (
                  <MdOutlineCheckBoxOutlineBlank size={20} />
                ) : (
                  <MdOutlineCheckBox size={20} />
                )}
                <span style={{ paddingLeft: "15px" }}>
                  I agree to {<a href="#">privacy policy & terms</a>}
                </span>
              </p>

              <Button className="wrapper-input-box-signup" type="submit">
                Sign up
              </Button>
              <p className="wrapper-fb-apple-icons">
                <img
                  src={facebook}
                  alt="google"
                  style={{ marginRight: "20px" }}
                />
                <img src={apple} alt="apple" style={{ marginRight: "20px" }} />
              </p>
            </form>
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};

export default SignupPage;