import { Box, styled } from '@mui/material'
import Questions from './Questions/Questions'



const questions = [
  {
    id: 1,  
    question:"What is The Coach Corner?",
    ans:"The Coach Corner is a communication and strategy platform designed for coaches and players. It provides a centralized location for coaches and players to communicate, collaborate, and share strategies."
  },
  {
    id: 2,  
    question:"How do I create an account on The Coach Corner?",
    ans:`To create an account on The Coach Corner, click on the "Sign Up" button on the homepage and follow the prompts. You'll need to provide your name, email address, and create a password.`
},
{
  id: 3,  
  question:"How do I connect with other coaches or players on The Coach Corner?",
  ans:"To connect with other coaches or players on The Coach Corner, search for their profile using the search bar or browse the directory. Once you find their profile, you can send them a message or request to connect."
},
{
  id: 4,  
  question:"What kind of coaching strategies can I find on The Coach Corner?",
  ans:"The Coach Corner provides a variety of coaching strategies, including offensive and defensive strategies, game plans, drills, and training plans. Coaches can also share their own strategies with other coaches and players."
},
{
  id: 5,  
  question:"Can I use The Coach Corner on my mobile device?",
  ans:"Yes! The Coach Corner is optimized for use on mobile devices. Simply visit the website on your smartphone or tablet and log in to access all of the features and functionalities of the platform."
},
{
  id: 6,  
  question:"Is my personal information secure on The Coach Corner?",
  ans:"Yes. The Coach Corner takes the security and privacy of its users seriously. We use industry-standard encryption and security protocols to protect your personal information."
},
{
  id: 7,  
  question:"How do I report inappropriate behavior or content on The Coach Corner?",
  ans:`If you come across inappropriate behavior or content on The Coach Corner, please report it to our support team immediately. You can do this by clicking on the "Report" button next to the content or by sending an email to our support team at support@thecoachcorner.com.`
},
{
  id: 8,  
  question:"How do I join a team on The Coach Corner?",
  ans:`To join a team on The Coach Corner, you will need to receive an invitation from a coach. If you are interested in joining a team, you can reach out to a coach on the platform and request to join their team.`
},
{
  id: 9,  
  question:"How can I communicate with my coach on The Coach Corner?",
  ans:`You can communicate with your coach on The Coach Corner through the platform's messaging system. Simply go to your coach's profile and click on the "Message" button to send them a message.`
},
{
  id: 10,  
  question:"Can I share my own strategies with other players on The Coach Corner?",
  ans:`Yes! The Coach Corner is a platform for sharing strategies and collaborating with other coaches and players. You can share your own strategies with other players on the platform to help improve your team's performance.`
}
]




const FAQsForm = () => {
  return (
    <div className='faqs--wrapper'>
       <Questions questions={questions}/>
    </div>  
  )
}

export default FAQsForm