import React from 'react'
import { Sidebar } from './NewHeader';
import {Link} from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';


const sidebarItem = [
    {
      text: "Home",
      path: "/"
    },
    {
      text: 'Features',
      path: '/pages/features'
    },
    {
      text: 'Pricing',
      path: '/pages/pricing'
    },
    {
      text: 'About Us',
      path: '/pages/about-us'
    },
    {
      text: "FAQs",
      path: "/pages/faqs"
    },
    {
      text: "Contact us",
      path: "/pages/contact-us"
    },
    {
      text: "Login",
      path: "/account/login"
    },
    {
      text: "Register",
      path: "/account/register"
    },
  ]
  
  interface Props {
    isOpen: boolean;
    close: () => void;
  }
  const HeaderSidebar: React.FC<Props> = ({ close }) => {
    return (
      <Sidebar id="sidebarMenu" >
        <div className="overlay">
          <nav onClick={(event) => event.stopPropagation()}>
            <ul>
              {sidebarItem.map(item => {
                return (
                  <li>
                    <Link to={item.path} onClick={close} >
                        <span>{item.text}</span>
                    </Link>
                  </li>
                )
              })}
            </ul>    
          </nav>
          <div className="closebtn" onClick={close}>
            <CloseIcon style={{fontSize:"40px", color:"White"}}/>
          </div>
        </div>
      </Sidebar>
    );
  };
  

  
  
export default HeaderSidebar