import React from "react";
import { Link } from "react-router-dom";
import { Grid,styled } from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import AddLocation from '@mui/icons-material/AddLocation';
import YouTube from '@mui/icons-material/YouTube';
import Twitter from '@mui/icons-material/Twitter';
import Facebook from '@mui/icons-material/Facebook';
import { Instagram } from "@mui/icons-material";
import Container from "./Container";
import logo from '../logo.png';

const SectionWrapper = styled("div")({
  "& .footer": {
    background: "var(--primary-color)",
    "& .footer-top": {
      background: "var(--primary-color)",
      padding: "2em 5em",
      maxWidth:"1440px",
      textAlign: "left",
      margin: 'auto',
      "@media(max-width:992px)":{
        padding: "2em 1em 1em",
      },
      "& .footer--contanier":{
        "@media(max-width:992px)":{
          gap:'1em',
          "& div.align-center.logo-block, div.align-center.last-block":{
            flexBasis: '100%'
          },
          "& > div.align-center":{
            flexBasis: 'calc(50% - 1em )',
            textAlign: 'left',
            margin: '0',
            padding: '0'
          }
        }
      },
      "& .logo-box":{
         alignItems: 'center',
        "& h2":{
          fontFamily: 'var(--button-font)',
          fontWeight: '900',
          color: 'rgb(68 119 183)',
          fontSize: '2em',
        }
      },
      "& .border-bottom":{
        borderBottom:"1px solid var(--colorThemeBlue)",
        "& .align-center":{
          alignItems:'center',
          justifyContent: 'center',
          "&.logo-block":{
            alignItems: 'start'
          }
        }
      },
      "& .border-left":{
        borderLeft:"1px solid var(--colorThemeBlue)",
        margin:"30px auto",
        paddingLeft:"15px",
        color:"white",
        fontFamily: "var(--button-font)",
        "@media (max-width: 992px)":{
          border:"none"
        }
        
      },
      "& .margin-top-0":{
        marginTop:"0px"
      },
      "& h2":{
          color:"white",
          fontSize:"3rem",
          margin:"0px",
          justifyContent:"left"
      },
      "& .padding-2-rem":{
        padding:"2rem",
        "@media (max-width: 768px)":{
          padding:"1em",
          justifyContent: 'center',
          gap: '1em'
        }
    },
    "& .ml-2":{
      
        marginLeft: "-20px",
      
    },
    "& li":{
      padding:"3px 0px",
      marginBottom: '10px',
    },
    "& .social-media":{
      display:"flex",
      alignItems:"center",
      "& .list__flex":{
        display:"flex",
        alignItems:"center",
        gap: '0.5em',
        "&.address-link":{
          alignItems: 'start'
        },
        "&.social--links":{
          gap: '0.5em'
        }
      },
    },
    "@media (max-width: 576px)": {
      textAlign: "center",
      "& .align-center":{
        
      },
      "& .left-align":{
        textAlign:"left",
      }
    },
    },
    "& .footer-bottom": {
      background: "var(--primary-color)",     
      "& *": {
        fontFamily: "var(--button-font)",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        color: "white",
      },
      "& .footer-bottom__wrapper": {
        padding: "1em 0em",
        textAlign: "center",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        "@media(max-width:992px)":{
          flexDirection: 'column',
          justifyContent: 'center',
          padding: "1em 0.5em",
          gap: '0.8em',
        }
      },
      "& p": {
        margin: "0",
      },
    },
    "& a": {
      color: "inherit",
    },
  },
});

function Footer() {
  return (
    <footer className="main-footer">
      <SectionWrapper>
        <div className="footer mt-auto">
          <div className="footer-top">
              <Grid container className="border-bottom footer--contanier">
                <div className="align-center logo-block">
                  <div className="flex-box padding-2-rem">
                    <a href="/" className="flex-box logo-box">
                      <img
                        src={logo}
                        className="logo-img"
                        alt="The Coach Corner - Offical Logo"
                        loading="lazy" style={{ height: "100px" }}/>
                      <h2 className="">COACH <br/>CORNER</h2>
                    </a>
                  </div>
                </div>
                <div className="align-center left-align border-left">
                  <h3 className="margin-top-0">INFORMATION</h3>
                  <ul>
                    <li>
                      <Link to="/pages/features">Features</Link>
                    </li>
                    <li>
                      <Link to="/pages/pricing">Prices</Link>
                    </li>
                    <li>
                      <Link to="/pages/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/pages/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
                <div className="align-center left-align border-left">
                  <h3 className="margin-top-0">QUICK LINKS</h3>
                  <ul>
                    <li>
                      <Link to="/pages/faqs">FAQs</Link>
                    </li>
                    <li>
                      <Link to="/pages/contact-us">Contact us</Link>
                    </li>
                    <li>
                      <Link to="/account/login">Login</Link>
                    </li>
                    <li>
                      <Link to="/account/register">Register</Link>
                    </li>
                  </ul>
                </div>
                <div className="align-center border-left last-block">
                  <div>
                    <h3 className="margin-top-0">GET TO US</h3>
                    <div>
                      <ul>
                        <li className="social-media">
                          <a href="tel:+1(202)4365839" target='_blank' className="list__flex">
                            <PhoneIcon/><span>+1 (202) 4365839</span> 
                          </a>
                        </li>
                        <li className="social-media">
                          <a href="mailto:info@thecoachcorner.com" target='_blank' className="list__flex">
                            <EmailIcon/><span>info@thecoachcorner.com</span>
                          </a>
                        </li>
                        <li className="social-media">
                          <span className="list__flex social--links">
                            <a href="#" target='_blank'>
                            <Facebook/>
                            </a>
                            <a href="https://twitter.com/TheCoachsCorne3" target='_blank'
                               title="The Coach Corner on Twitter">
                            <Twitter/>
                            </a>
                            <a href="#" target='_blank'>
                            <YouTube/>
                            </a>
                            <a href="https://www.instagram.com/coachcorner__/" target='_blank' 
                               title="The Coach Corner on Instagram">
                            <Instagram/>
                            </a>
                          </span>
                        </li>
                        <li className="social-media">
                          <a href="https://goo.gl/maps/JGuk43sfUKz4u7g78"  target='_blank' 
                            className="list__flex address-link">
                            <AddLocation/><span>P/O Box 45832, Baton Rouge, LA 70895</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Grid>
            
          </div>
          <div className="footer-bottom">
            <Container>
              <div className="footer-bottom__wrapper">
                <p><a href="/">Copyright © 2022 The Coach Corner. All rights reserved.</a></p>
                <p>
                  <a href="https://rftechnologies.com.pk" 
                    target="_blank" title="Powered By RF Technologies">
                    Powered By RF Technologies
                  </a>
                </p>
              </div>
              </Container>
          </div>
        </div>
      </SectionWrapper>
    </footer>
  );
}

export default Footer;
