import { styled, Box } from "@mui/material";
import bgimg1 from "../../../images/images/img1.jpg";


const TextWrapper = styled(Box)({
  // fontFamily: "Poppins",
  "@media (max-width: 576px)": {
    height: "200px",
  },
  "& .bg-img": {
    backgroundImage: `url(${bgimg1})`,
    filter:"brightness(50%)",
    backgroundBlendMode: "multiply",
    width: "100%",
    backgroundRepeat: "no-repeat",
    position: "relative",
    backgroundPosition: "center",
    padding: "14rem 3rem",
    zIndex: "-1",
    backgroundSize: "cover",
    "@media (max-width: 576px)": {
      backgroundImage: `url(${bgimg1})`,
      backgroundBlendMode: "multiply",
      width: "100%",
      backgroundRepeat: "no-repeat",
      position: "relative",
      backgroundPosition: "center",
      zIndex: "-1",
      height: "100px",
      backgroundSize: "cover",
    },
  },
  "& .wrapper-heading": {
    position: "absolute",
    fontSize: "5rem",
    display:"flex",
    fontWeight: 700,
    letterSpacing: "0.43px",
    textAlign: "center",
    color: "#FFFFFF",
    top: "236px",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "@media (max-width: 576px)": {
      position: "absolute",
      fontSize: "2rem",
      fontWeight: 600,
      display:"flex",
      letterSpacing: "0.43px",
      // lineHeight: "20.14px",
      textAlign: "left",
      color: "#FFFFFF",

      // top: "160px",
      width: "90%",
    },
  },
  "& .wrapper-text": {
    fontFamily:"var(--heading-font)",
    position: "absolute",
    fontSize: "3rem",
    fontWeight: 400,
    letterSpacing: "0.43px",
    padding: "0px 0px 15px 0px",
    // lineHeight: "30.14px",
    color: "#FFFFFF",
    top: "130px",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "@media (max-width: 576px)": {
      position: "absolute",
      fontSize: "14px",
      fontWeight: 600,
      letterSpacing: "0.43px",
      // lineHeight: "30.14px",
      textAlign: "center",
      color: "#FFFFFF",
      top: "200px",
      width: "90%",
     
    },
  },
});
///////////////////////////////////////////////////////////////////////////////
const UpperSection = () => {
  return (
    <TextWrapper>
      <div>
        <div className="bg-img"></div>
        <h1 className="wrapper-heading section__title">FREQUENTLY ASKED QUESTIONS</h1>
      </div>
    </TextWrapper>
  );
};

export default UpperSection;
