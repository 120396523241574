import React from 'react'
import { Box, Container, styled } from '@mui/material'
import { Link } from 'react-router-dom'

const LogoSectionWrapper = styled(Box)({
    padding: '4em 1em 1em',
    margin: '0em 0em 4em',
    textAlign: 'center',
    // maxWidth:"1320px",
    backgroundColor:"#fff",
    backgroundImage: "url(https://campo.bold-themes-cdn.com/demo-01/wp-content/uploads/sites/2/2022/11/top_cover_home_03.png)",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    "@media (max-width:768px)": {
      padding: '4em 1em 1em',
      margin: '0em',
      "& .rich-text-container":{
        padding: '0em'
      }
    },
    "& .btn__wrapper":{
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    "& .section__title":{
      maxWidth:"500px", 
      margin:"auto", 
      "@media (max-width:700px)": {
        width:"100%",
      },
    },
    "& h2": { 
      color: "var(--primary-color)", 
      fontWeight: "600", 
      fontFamily:"var(--button-font)",
      },
      "& p": { 
        fontFamily:"var(--button-font)",
        color: "#333", 
        fontSize: '1.2em',
        fontWeight: "500", 
        },
    "& .logo__section-content": { 
      maxWidth:"760px", 
      margin:"auto", 
      "@media (max-width:768px)": {
        width:"100%"
      },

    },
})



const RichText = ({title,content}) => {
  return (
    <section className='index-section rich-text-section' id='rich-text-section'>
      <LogoSectionWrapper>
        <Container className='rich-text-container'>
          <div className='work-sample-container' data-aos="fade-up">
            <h2>{title}</h2>
            <div class="rte rte-settings logo__section-content">
              <p>{content}</p>
            </div> 
            <div className='btn__wrapper'>
              <Link to='pages/contact-us' 
                    className='btn btn--primary btn--half'>
                      Join Our Community</Link>
            </div>
          </div>
        </Container>
      </LogoSectionWrapper>
    </section>
  )
}

export default RichText